/*
 * Only imports in this file, no real style.
 *
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*
 * Sub-site specific variables.
 *
 */
body.sz-main {
  --color-fg-primary: black;
  --color-fg-secondary: black;
  --color-fg-tertiary: #008755;
  --color-bg-primary: white;
  --color-bg-secondary: #c4ced3;
  --color-bg-tertiary: #f4f5f6;
  --color-bg-quaternary: #d7e1e6;
  --color-bg-quinary: #F3F9F1;
  --color-bg-senary: #E0EFDA;
  --color-mobile-menu-bg: #eef9f7;
  --color-mobile-menu-links: #000000;
  --color-menu-links: #ffffff;
  --color-cta-primary: #008755;
  --color-cta-secondary: #000000;
  --color-cta-tertiary: #ff2e00;
  --color-cta-tertiary-inactive: #958683;
  --color-cta-primary-active: #005435;
  --color-cta-strong-active: #b62100;
  --color-heading-primary: #008755;
  --color-heading-secondary: #ffffff;
  --color-heading-tertiary: #000000;
  --font-size: 1rem;
  --font-size-headline: 1.2rem;
  --grid-gutter: 30px;
  --grid-min-margin: 40px;
  --grid-min-margin-mobile: 10px;
  --grid-max-page-width: 1240px;
  --font-paragraph: "circular", sans-serif;
  --font-head-primary: "circular", sans-serif;
  --font-head-secondary: "Hackney-Vector", sans-serif;
  --logo-url: url(/wp-content/themes/szero/assets/sz-main/suicide-zero_logotype.svg);
  --arrow-right-url: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/arrow-right.svg);
  --divider-url: url(/wp-content/themes/szero/assets/sz-main/divider.jpg);
  --illustration-stars: url(/wp-content/themes/szero/assets/sz-main/illustration-stars.png);
  --link-external: url(/wp-content/themes/szero/assets/sz-main/link-external.png);
  --paper-background: url(/wp-content/themes/szero/assets/sz-main/paper-background.png);
  --dotted-background: url(/wp-content/themes/szero/assets/sz-main/dotted-background.png);
  --heart-url: url(/wp-content/themes/szero/assets/sz-main/heart.svg);
  --arrow-down-url: url(/wp-content/themes/szero/assets/sz-main/arrow-down.svg);
  --external-link-url: url(/wp-content/themes/szero/assets/sz-main/external-link-icon.svg);
  --page-404-image: url(/wp-content/themes/szero/assets/sz-main/askmoln-sz.png);
  --cube-animation: url(/wp-content/themes/szero/assets/sz-main/cube-sz.mp4);
  --instagram-link: url(/wp-content/themes/szero/assets/sz-main/instagram.svg);
  --linkedin-link: url(/wp-content/themes/szero/assets/sz-main/linkedin.svg);
  --facebook-link: url(/wp-content/themes/szero/assets/sz-main/facebook.svg);
  --youtube-link: url(/wp-content/themes/szero/assets/sz-main/youtube.svg);
  --logo-footer-url: url(/wp-content/themes/szero/assets/sz-main/suicide-zero_logotype.svg);
  --logo-footer-second-url: url(/wp-content/themes/szero/assets/sz-main/second-footer-logo.svg);
  --quote-icon-url: url(/wp-content/themes/szero/assets/sz-main/quote-icon.svg);
  --yellow-dot: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/dot_yellow.png);
  --swish-logo: url(/wp-content/themes/szero/assets/sz-main/swish-logo.svg);
  --close-popup-icon: url(/wp-content/themes/szero/assets/images/close-popup-icon.svg);
}

body.sz-parent-support {
  --color-fg-primary: black;
  --color-fg-secondary: #c51a1b;
  --color-fg-tertiary: #c51a1b;
  --color-bg-primary: white;
  --color-bg-secondary: #f8e3e3;
  --color-bg-tertiary: #f5f6fa;
  --color-bg-footer: #F4F4F4;
  --color-mobile-menu-bg: #f9f6f6;
  --color-mobile-menu-links: #000000;
  --color-menu-links: #ffffff;
  --color-cta-primary: #c51a1b;
  --color-cta-secondary: #234d96;
  --color-cta-primary-active: #931415;
  --color-cta-strong-active: #b62100;
  --color-cta-tertiary: #ff2e00;
  --color-cta-tertiary-inactive: #958683;
  --color-heading-primary: #c51a1b;
  --color-heading-secondary: #c51a1b;
  --color-heading-tertiary: #000;
  --font-size: 1rem;
  --font-size-headline: 1.2rem;
  --grid-gutter: 30px;
  --grid-min-margin: 40px;
  --grid-min-margin-mobile: 10px;
  --grid-max-page-width: 1240px;
  --font-paragraph: "Inter", sans-serif;
  --font-head-primary: "questa-slab", serif;
  --font-head-secondary: "Livsviktigskisstypo", sans-serif;
  --logo-url: url(/wp-content/themes/szero/assets/sz-parent-support/LS-logotyp.png);
  --arrow-right-url: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/arrow-right.svg);
  --divider-url: url(/wp-content/themes/szero/assets/sz-main/divider.jpg);
  --link-external: url(/wp-content/themes/szero/assets/sz-main/link-external.png);
  --paper-background: url(/wp-content/themes/szero/assets/sz-main/paper-background.png);
  --dotted-background: url(/wp-content/themes/szero/assets/sz-main/dotted-background.png);
  --arrow-down-url: url(/wp-content/themes/szero/assets/sz-main/arrow-down.svg);
  --external-link-url: url(/wp-content/themes/szero/assets/sz-main/external-link-icon.svg);
  --book-image-url: url(/wp-content/themes/szero/assets/sz-parent-support/ls-book.jpg);
  --language-icon-url: url(/wp-content/themes/szero/assets/sz-parent-support/language-icon.jpg);
  --page-404-image: url(/wp-content/themes/szero/assets/sz-parent-support/regnmoln-ls.png);
  --instagram-link: url(/wp-content/themes/szero/assets/sz-main/instagram.svg);
  --linkedin-link: url(/wp-content/themes/szero/assets/sz-main/linkedin.svg);
  --facebook-link: url(/wp-content/themes/szero/assets/sz-main/facebook.svg);
  --youtube-link: url(/wp-content/themes/szero/assets/sz-main/youtube.svg);
  --logo-footer-url: url(/wp-content/themes/szero/assets/sz-parent-support/logo-footer.png);
  --logo-footer-second-url: url(/wp-content/themes/szero/assets/sz-parent-support/footer-logo-second.png);
  --quote-icon-url: url(/wp-content/themes/szero/assets/sz-parent-support/quote-icon.svg);
  --yellow-dot: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/dot_yellow.png);
}

body.sz-important-talks-in-school {
  --color-fg-primary: black;
  --color-fg-secondary: #234d96;
  --color-fg-tertiary: #c51a1b;
  --color-fg-quaternary: white;
  --color-bg-primary: white;
  --color-bg-secondary: #f5f6fa;
  --color-bg-tertiary: #f8e3e3;
  --color-mobile-menu-bg: #f9fafc;
  --color-mobile-menu-links: #000000;
  --color-menu-links: #ffffff;
  --color-cta-primary: #234d96;
  --color-cta-secondary: #c51a1b;
  --color-cta-primary-active: #1b386c;
  --color-cta-strong-active: #b62100;
  --color-cta-tertiary: #ff2e00;
  --color-cta-tertiary-inactive: #958683;
  --color-cta-listing: #dddfe7;
  --color-heading-primary: #234d96;
  --color-heading-secondary: #c51a1b;
  --color-heading-tertiary: #000;
  --font-size: 1rem;
  --font-size-headline: 1.2rem;
  --grid-gutter: 30px;
  --grid-min-margin: 40px;
  --grid-min-margin-mobile: 10px;
  --grid-max-page-width: 1240px;
  --font-paragraph: "Inter", sans-serif;
  --font-head-primary: "questa-slab", serif;
  --font-head-secondary: "Livsviktigskisstypo", sans-serif;
  --logo-url: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/LSIS-logotyp.png);
  --arrow-right-url: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/arrow-right.svg);
  --divider-url: url(/wp-content/themes/szero/assets/sz-main/divider.jpg);
  --link-external: url(/wp-content/themes/szero/assets/sz-main/link-external.png);
  --paper-background: url(/wp-content/themes/szero/assets/sz-main/paper-background.png);
  --dotted-background: url(/wp-content/themes/szero/assets/sz-main/dotted-background.png);
  --arrow-down-url: url(/wp-content/themes/szero/assets/sz-main/arrow-down.svg);
  --external-link-url: url(/wp-content/themes/szero/assets/sz-main/external-link-icon.svg);
  --profile-picture-url: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/profile-picture.png);
  --page-404-image: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/regnmoln-ls.png);
  --instagram-link: url(/wp-content/themes/szero/assets/sz-main/instagram.svg);
  --linkedin-link: url(/wp-content/themes/szero/assets/sz-main/linkedin.svg);
  --facebook-link: url(/wp-content/themes/szero/assets/sz-main/facebook.svg);
  --youtube-link: url(/wp-content/themes/szero/assets/sz-main/youtube.svg);
  --logo-footer-url: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/LSIS-logotyp.png);
  --logo-footer-second-url: url(/wp-content/themes/szero/assets/sz-parent-support/footer-logo-second.png);
  --quote-icon-url: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/quote-icon.svg);
  --yellow-dot: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/dot_yellow.png);
  --plus-icon: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/Plus.svg);
  --minus-icon: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/Minus.svg);
  --high-five: url(/wp-content/themes/szero/assets/sz-important-talks-in-school/highfive.jpg);
}

/*
 * Page structure, no design here, only columns and placement
 *
 */
body {
  --grid-margin: max(var(--grid-min-margin), calc((100vw - var(--grid-max-page-width)) / 2));
  overflow-x: hidden;
  min-height: 100vh;
  grid-template-rows: [browser-top header-top] min-content [header-bottom main-top] 1fr [main-bottom footer-top] min-content [footer-bottom browser-bottom];
}
body.one-column, body.one-column > nav, body.one-column > footer {
  display: grid;
  gap: var(--grid-gutter);
  grid-template-columns: [browser-left] var(--grid-margin) [page-left main-left right-aside-left left-aside-left] minmax(10px, 1fr) [main-right right-aside-right left-aside-right page-right] var(--grid-margin) [browser-right];
}
body.two-column, body.two-column > nav, body.two-column > footer {
  display: grid;
  gap: var(--grid-gutter);
  grid-template-columns: [browser-left] var(--grid-margin) [page-left left-aside-left] minmax(10px, 1fr) [left-aside-right main-left right-aside-left] minmax(10px, 3fr) [main-right right-aside-right left-aside-right page-right] var(--grid-margin) [browser-right];
}
body.three-column, body.three-column > nav, body.three-column > footer {
  display: grid;
  gap: var(--grid-gutter);
  grid-template-columns: [browser-left] var(--grid-margin) [page-left left-aside-left] minmax(10px, 1fr) [left-aside-right main-left] minmax(10px, 3fr) [main-right right-aside-left] minmax(10px, 1fr) [right-aside-right page-right] var(--grid-margin) [browser-right];
}

header {
  grid-column: page-left/page-right;
}

main {
  grid-column: main-left/main-right;
  grid-row-start: main-top;
  grid-row-end: main-bottom;
}

aside.left {
  grid-column: left-aside-left/left-aside-right;
  grid-row: main-top/main-bottom;
}

body.one-column > aside.left {
  display: none;
}

aside.right {
  grid-column: right-aside-left/right-aside-right;
  grid-row: main-top/main-bottom;
}

body.one-column > aside.right,
body.two-column > aside.right {
  display: none;
}

footer {
  grid-column: browser-left/browser-right;
}

footer > div {
  grid-column: page-left/page-right;
}

main > * {
  position: relative;
}

@media (max-width: 600px) {
  body.one-column,
  body.two-column,
  body.three-column,
  body.one-column > footer {
    grid-template-columns: [browser-left] var(--grid-min-margin-mobile) [page-left main-left right-aside-left left-aside-left] minmax(10px, 1fr) [main-right right-aside-right left-aside-right page-right] var(--grid-min-margin-mobile) [browser-right];
    grid-template-rows: auto;
  }
  body.one-column > *,
  body.two-column > *,
  body.three-column > *,
  body.one-column > footer > * {
    grid-row: auto !important;
  }
}
.two-column .full-width {
  width: 100vw;
  margin-left: calc(0px - var(--grid-margin) - var(--grid-gutter));
}
@media (max-width: 600px) {
  .two-column .full-width {
    margin-left: calc(0px - var(--grid-min-margin-mobile) - var(--grid-gutter));
  }
}

.one-column .full-width,
.three-column .full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (max-width: 600px) {
  .one-column .full-width-mobile,
  .two-column .full-width-mobile,
  .three-column .full-width-mobile {
    width: 98vw !important;
    margin-left: calc(50% - 49vw) !important;
  }
}

.two-column .full-width-background {
  background: #ff00ff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100vw;
  margin-left: calc(0px - var(--grid-margin) - var(--grid-gutter));
}
@media (max-width: 600px) {
  .two-column .full-width-background {
    margin-left: calc(0px - var(--grid-min-margin-mobile) - var(--grid-gutter));
  }
}

.one-column .full-width-background,
.three-column .full-width-background {
  background: #ff00ff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

body.debug-structure-outline > *:not(:empty) {
  box-shadow: 0px 0px 1px 1px var(--color-fg-secondary);
}

/*
 * Page visual design, things we see that are not in an editor block.
 * At lease each php file has its own file here.
 */
.breadcrumb {
  margin-bottom: 48px;
  font-size: 0.813rem;
  line-height: 140%;
  font-weight: 400;
  text-transform: uppercase;
}
.breadcrumb a {
  text-decoration: none;
  color: var(--color-fg-primary);
  font-size: 0.813rem;
  line-height: 140%;
}
.breadcrumb a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .breadcrumb {
    font-size: 0.75rem;
  }
  .breadcrumb a {
    font-size: 0.75rem;
  }
}
body {
  background: var(--color-bg-primary);
}

body.arabic section p,
body.arabic section h1,
body.arabic section h2,
body.arabic section h3,
body.arabic section h4,
body.arabic section h5,
body.arabic section h6,
body.arabic section button,
body.arabic section a,
body.arabic section input {
  direction: rtl;
}

.container404 {
  margin-top: 100px;
  display: flex;
  gap: 60px;
}
.container404 .image404 {
  background-image: var(--page-404-image);
  width: 200px;
  height: 210px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.container404 .item404-text {
  max-width: 650px;
}
.container404 .item404-text h1 {
  font-size: 2.625rem;
  line-height: 100%;
  font-family: var(--font-head-secondary);
  margin-bottom: 20px;
  color: var(--color-heading-primary);
  text-transform: uppercase;
}
.container404 .item404-text h1 span {
  display: block;
  font-size: 1.625rem;
  line-height: 100%;
  margin-top: 26px;
}
.container404 .item404-text p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
}
.container404 .item404-text a {
  background: var(--color-cta-primary);
  border-radius: 23px;
  bottom: 0;
  color: var(--color-bg-primary);
  display: inline-block;
  font-family: var(--font-head-primary);
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  height: 46px;
  line-height: 2.8rem;
  margin-top: 10px;
  padding: 0 30px;
  text-decoration: none;
}

.sz-main .container404 .item404-text {
  max-width: 650px;
}
.sz-main .container404 .item404-text h1 {
  font-size: 4.5rem;
  line-height: 100%;
  margin-bottom: 10px;
}
.sz-main .container404 .item404-text h1 span {
  font-size: 3.5rem;
  line-height: 100%;
  margin-top: 0px;
}
.sz-main .container404 .item404-text p {
  font-size: 1.125rem;
  line-height: 140%;
  margin-bottom: 20px;
}
.sz-main .container404 .item404-text a {
  background: none;
  border-bottom: 3px solid var(--color-fg-tertiary);
  border-radius: 0;
  color: var(--color-cta-primary);
  font-family: var(--font-paragraph);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  height: auto;
  line-height: 160%;
  padding: 0;
  text-decoration: none;
  position: relative;
}

@media (max-width: 800px) {
  .container404 {
    gap: 30px;
  }
  .container404 .image404 {
    height: 130px;
    width: 122px;
  }
  .container404 .item404-text h1 {
    font-size: 3.5rem;
  }
  .container404 .item404-text h1 span {
    font-size: 2.5rem;
  }
}
@media (max-width: 600px) {
  .container404 .image404 {
    height: 90px;
    width: 84px;
  }
  .container404 .item404-text h1 {
    font-size: 2.5rem;
  }
  .container404 .item404-text h1 span {
    font-size: 2rem;
  }
  .container404 .item404-text p {
    font-size: 1rem;
  }
}
article.article {
  max-width: 780px;
  margin: 0 auto 60px auto;
}
article.article .entry-header .post-image {
  height: 420px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
}
article.article .entry-header .post-image img {
  width: 100%;
  overflow: hidden;
  display: flex;
}
article.article .entry-header .image-caption {
  margin-top: 5px;
  font-size: 0.813rem;
}
article.article .entry-header h1.entry-title {
  margin-top: 24px;
  font-size: 1.625rem;
  line-height: 130%;
}
article.article .entry-header .news-date {
  font-size: 0.813rem;
  color: var(--color-heading-primary);
  text-transform: lowercase;
  margin-top: 20px;
}
article.article .entry-content .excerpt {
  margin: 30px 0;
  line-height: 150%;
  margin-bottom: 20px;
  font-size: 1.125rem;
  font-weight: 700;
}
article.article .entry-content p {
  font-size: 1rem;
  line-height: 140%;
  margin-bottom: 20px;
}
article.article .entry-content p.quote {
  font-style: italic;
  padding-left: 50px;
  background: var(--quote-icon-url) left top no-repeat;
}
article.article .entry-content p a {
  font-weight: 700;
  color: var(--color-cta-primary);
  border-bottom: 3px solid;
  text-decoration: none;
}
article.article .entry-content ul,
article.article .entry-content ol {
  margin-left: 20px;
  margin-bottom: 20px;
}
article.article .entry-content img.article-image {
  max-width: 100%;
  margin-bottom: 20px;
}
article.article .entry-content .article-link {
  margin-bottom: 20px;
}
article.article .entry-content .article-link a {
  font-size: 1rem;
  line-height: 160%;
  color: var(--color-cta-primary);
  font-weight: 700;
  text-decoration: none;
  border-bottom: 3px solid;
}
article.article .entry-content h1,
article.article .entry-content h2,
article.article .entry-content h3,
article.article .entry-content h4,
article.article .entry-content h5,
article.article .entry-content h6 {
  font-size: 1.125rem;
  color: var(--color-cta-primary-active);
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 6px;
}
article.article .navigation-between-posts {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 50px;
}
article.article .navigation-between-posts .navigation-button {
  font-weight: 700;
  font-size: 1.063rem;
  width: 140px;
  text-align: center;
  height: 40px;
  border-radius: 20px;
  display: block;
  text-decoration: none;
}
article.article .navigation-between-posts .navigation-button.previous-post-button {
  border: 2px solid var(--color-cta-primary);
  line-height: 36px;
  color: var(--color-cta-primary);
}
article.article .navigation-between-posts .navigation-button.next-post-button {
  background: var(--color-cta-primary);
  line-height: 40px;
  color: var(--color-heading-secondary);
}

.education-section {
  margin: 0 auto 30px;
  max-width: 780px;
  background: var(--color-bg-quinary);
  padding: 32px;
  border-radius: 6px;
}
.education-section p {
  margin-bottom: 8px;
}
.education-section .progress-bar-wrapper {
  display: flex;
  align-items: center;
}
.education-section .progress-bar-wrapper p {
  right: 32px;
  position: absolute;
}
.education-section .progress-bar-wrapper .progress-bar-container {
  background: var(--color-bg-primary);
  border: 1px solid #d7d8d9;
  position: relative;
  width: 90%;
  border-radius: 100px;
}
.education-section .progress-bar-wrapper .progress-bar-container .progress-bar {
  height: 11px;
  border-radius: 100px;
  background: #38b854;
}
.education-section .progress-bar-wrapper .progress-bar-container .progress-bar-marker {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.education-section .progress-bar-wrapper .progress-bar-container .progress-bar-marker .marker {
  border: 1px solid #d7d8d9;
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
}
.education-section .progress-bar-wrapper .progress-bar-container .progress-bar-marker .marker.inbar {
  border: 1px solid #2c9142;
}
.education-section .education-chapters {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.education-section .education-chapters .chapter a {
  color: #000;
  text-decoration: none;
  line-height: 130%;
}
.education-section .education-chapters .chapter.active {
  font-weight: 700;
}
.education-section .page-information {
  margin-bottom: 26.5px;
}
.education-section .page-information p {
  font-size: 0.938rem;
}
.education-section .education-text-section {
  margin-bottom: 48px;
}
.education-section .education-text-section h1,
.education-section .education-text-section h2,
.education-section .education-text-section h3,
.education-section .education-text-section h4,
.education-section .education-text-section h5,
.education-section .education-text-section h6 {
  margin-bottom: 24px;
  line-height: 110%;
}
.education-section .education-text-section h1 {
  font-size: 2.25rem;
}
.education-section .education-text-section h2 {
  font-size: 1.5rem;
}
.education-section .education-text-section h3 {
  font-size: 1.375rem;
}
.education-section .education-text-section p {
  margin-bottom: 20px;
}
.education-section .fact-box {
  background: var(--color-bg-senary);
  border-radius: 6px;
  padding: 32px;
}
.education-section .fact-box ul,
.education-section .fact-box li {
  margin-bottom: 12px;
}
.education-section .education-image-section {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}
.education-section .education-image-section img {
  border-radius: 6px;
  max-width: 100%;
  max-height: 390px;
}
.education-section .video-section {
  margin-bottom: 48px;
}
.education-section .education-quiz-section .question-container {
  margin: 20px 0 40px;
}
.education-section .education-quiz-section .question {
  font-size: 1.17rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.education-section .education-quiz-section .radiobutton-container {
  cursor: pointer;
  display: block;
  font-size: 1rem;
  line-height: 28px;
  margin-bottom: 12px;
  padding-left: 40px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-weight: 700;
}
.education-section .education-quiz-section .radiobutton-container .checkmark {
  background-color: var(--color-bg-primary);
  border: 2px solid var(--color-cta-primary);
  border-radius: 50%;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}
.education-section .education-quiz-section .radiobutton-container .checkmark:after {
  background: var(--color-cta-primary);
  border-radius: 50%;
  content: "";
  display: none;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.education-section .education-quiz-section .radiobutton-container input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}
.education-section .education-quiz-section .radiobutton-container input:disabled ~ .checkmark {
  opacity: 0.5;
}
.education-section .education-quiz-section .radiobutton-container input:checked ~ .checkmark {
  border: 2px solid var(--color-cta-primary);
}
.education-section .education-quiz-section .radiobutton-container input:checked ~ .checkmark:after {
  display: block;
}
.education-section .education-quiz-section .question-explanation {
  margin-top: 20px;
  padding-left: 40px;
  display: none;
}
.education-section .education-quiz-section .question-explanation.display {
  display: block;
}
.education-section .education-quiz-section .question-explanation .question-result {
  font-weight: 700;
}
.education-section .education-quiz-section .question-explanation .question-result.correct {
  color: var(--color-cta-primary);
}
.education-section .education-quiz-section .question-explanation .question-result.wrong {
  color: var(--color-cta-tertiary);
}
.education-section .education-quiz-section .question-explanation a {
  color: var(--color-cta-secondary);
  font-weight: 700;
  text-underline-offset: 3px;
}
.education-section .education-quiz-section .quiz-divider {
  height: 1px;
  margin: 50px 10%;
  width: 80%;
  background-color: var(--color-bg-secondary);
}
.education-section .education-quiz-section .quiz-heading {
  font-size: 1.375rem;
}
.education-section .education-quiz-section .checkResult-container {
  text-align: center;
}
.education-section .education-quiz-section .checkResult-container button#checkResults {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  height: auto;
  line-height: 100%;
  max-height: 40px;
  padding: 12px 24px;
  background: var(--color-cta-primary);
  border-radius: 20px;
  border: none;
  color: var(--color-bg-primary);
  display: inline-block;
  margin-top: 20px;
  transition: all 0.2s;
  cursor: pointer;
}
.education-section .education-quiz-section .checkResult-container button#checkResults:hover {
  opacity: 0.6;
}

.education-navigation-between-posts {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 50px;
}
.education-navigation-between-posts .navigation-button {
  font-weight: 700;
  font-size: 1.063rem;
  width: 140px;
  text-align: center;
  height: 40px;
  border-radius: 20px;
  display: block;
  text-decoration: none;
}
.education-navigation-between-posts .navigation-button.previous-post-button {
  border: 2px solid var(--color-cta-primary);
  line-height: 36px;
  color: var(--color-cta-primary);
}
.education-navigation-between-posts .navigation-button.next-post-button {
  background: var(--color-cta-primary);
  line-height: 40px;
  color: var(--color-heading-secondary);
}

#result-section {
  display: none;
}
#result-section.display {
  display: block;
}
#result-section.display h2 {
  text-align: center;
}
#result-section.hide {
  display: none;
}
#result-section #result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
}
#result-section #result-container #result-score {
  width: 20%;
  min-width: 130px;
  font-size: 3rem;
  color: var(--color-cta-primary);
  font-weight: 700;
  text-align: center;
  line-height: 100%;
}
#result-section #result-container .result-text {
  text-align: left;
  width: 60%;
}
#result-section #result-container .result-text.hide {
  display: none;
}
#result-section #result-container .result-text .rulemailer-subscriber-form input[type=email] {
  border: 1px solid var(--color-fg-tertiary);
  border-radius: 6px;
  font-size: 1.063rem;
  height: 40px;
  margin-top: 10px;
  min-width: 250px;
  padding: 0 12px;
  width: 100%;
}
#result-section #result-container .result-text .rulemailer-subscriber-form .btn-wrapper {
  display: flex;
  justify-content: center;
}
#result-section #result-container .result-text .rulemailer-subscriber-form .btn-wrapper button {
  background-color: var(--color-cta-primary);
  border: none;
  border-radius: 20px;
  color: var(--color-heading-secondary);
  cursor: pointer;
  font-family: var(--font-paragraph);
  font-size: 1.063rem;
  font-weight: 700;
  height: 40px;
  padding: 0 20px 0 20px;
  transition: 0.2s all;
  margin-top: 20px;
}
#result-section #result-container .result-text .rulemailer-subscriber-form .btn-wrapper button:hover, #result-section #result-container .result-text .rulemailer-subscriber-form .btn-wrapper button:active {
  opacity: 0.6;
}
#result-section #result-container .result-text .rulemailer-email-check {
  display: none !important;
}
#result-section #result-container.hide {
  display: none;
}
#result-section .result-button-container {
  text-align: center;
}
#result-section .result-button-container button.result-button {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  height: auto;
  line-height: 100%;
  max-height: 40px;
  padding: 12px 24px;
  background: var(--color-cta-primary);
  border-radius: 20px;
  border: none;
  color: var(--color-bg-primary);
  margin-top: 20px;
  transition: all 0.2s;
  cursor: pointer;
  display: none;
}
#result-section .result-button-container button.result-button.display {
  display: inline-block;
}
#result-section .result-button-container button.result-button:hover {
  opacity: 0.6;
}
#result-section .form-section.display {
  display: block;
}
#result-section .form-section.hide {
  display: none;
}
#result-section .form-section .form-content-box {
  background: var(--color-bg-quinary);
  max-width: 70%;
  margin: 0 auto;
  padding: 0;
}
#result-section .form-section .form-content-box #send-diploma-button {
  background: var(--color-cta-primary);
  border: none;
  border-radius: 20px;
  color: var(--color-bg-primary);
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  height: auto;
  line-height: 100%;
  margin-top: 20px;
  max-height: 40px;
  padding: 12px 24px;
  transition: all 0.2s;
}
#result-section .form-section .form-content-box #send-diploma-button.disabled-btn {
  cursor: default;
  opacity: 0.5;
}

@media (max-width: 600px) {
  .education-section {
    padding: 16px 16px 24px 16px;
  }
  .education-section .progress-bar-wrapper p {
    right: 16px;
    top: 16px;
  }
  .education-section .progress-bar-wrapper .progress-bar-container {
    width: 100%;
  }
  .education-section .page-information {
    margin-bottom: 16px;
  }
  .education-section .education-text-section {
    margin-bottom: 24px;
  }
  .education-section .education-text-section h1,
  .education-section .education-text-section h2,
  .education-section .education-text-section h3,
  .education-section .education-text-section h4,
  .education-section .education-text-section h5,
  .education-section .education-text-section h6 {
    margin-bottom: 16px;
  }
  .education-section .education-text-section h1 {
    font-size: 1.875rem;
  }
  .education-section .education-text-section h2 {
    font-size: 1.375rem;
  }
  .education-section .education-text-section h3 {
    font-size: 1.25rem;
  }
  .education-section .education-text-section p {
    margin-bottom: 20px;
  }
  .education-section .fact-box {
    padding: 24px;
  }
  .education-section .education-quiz-section .quiz-heading {
    font-size: 1.25rem;
  }
  .education-section .education-quiz-section .question-explanation {
    padding-left: 0;
  }
  #result-section #result-container {
    flex-direction: column;
  }
  #result-section #result-container .result-text {
    width: 100%;
  }
}
body > footer {
  font-size: 0.938rem;
}
body > footer a {
  color: var(--color-fg-primary);
}

.footer-wrapper {
  padding: 40px 0px 40px 0px;
}
.footer-wrapper .logo {
  background-image: var(--logo-footer-url);
  width: 100px;
  background-repeat: no-repeat;
  height: 40px;
  margin-bottom: 20px;
}
.footer-wrapper .footer-content-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footer-wrapper .footer-content-wrapper .footer-first-section {
  flex-basis: 50%;
  padding-right: 20px;
}
.footer-wrapper .footer-content-wrapper .footer-first-section .social-media {
  display: flex;
  gap: 16px;
  margin-top: 18px;
  align-items: center;
}
.footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 28px;
  width: 28px;
}
.footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link a {
  display: block;
  height: 28px;
  width: 28px;
}
.footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.instagram-link {
  background-image: var(--instagram-link);
}
.footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.linkedin-link {
  background-image: var(--linkedin-link);
}
.footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.facebook-link {
  background-image: var(--facebook-link);
}
.footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.youtube-link {
  background-image: var(--youtube-link);
  height: 22px;
  width: 32px;
}
.footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.youtube-link a {
  height: 22px;
  width: 32px;
}
.footer-wrapper .footer-content-wrapper .footer-links-wrapper {
  display: flex;
  flex-direction: row;
  flex-basis: 40%;
}
.footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-first-section,
.footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-second-section {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}
.footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-first-section a,
.footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-second-section a {
  color: var(--color-cta-primary);
  padding-bottom: 0;
  font-weight: 700;
  display: inline-block;
}
.footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-first-section {
  margin-right: 80px;
}
.footer-wrapper .grecaptcha-badge {
  display: none;
}
.footer-wrapper .rulemailer-subscriber-form {
  max-width: 560px;
  width: 100%;
}
.footer-wrapper .rulemailer-subscriber-form #rule-optin-form {
  align-items: flex-end;
  display: flex;
  font-family: var(--font-paragraph);
  gap: 20px;
  max-width: 560px;
  width: 100%;
}
.footer-wrapper .rulemailer-subscriber-form #rule-optin-form div {
  position: absolute;
}
.footer-wrapper .rulemailer-subscriber-form input {
  border: none;
  border-radius: 6px;
  font-size: 1.063rem;
  height: 40px;
  margin-top: 10px;
  min-width: 250px;
  padding: 0 12px;
  width: 95%;
}
.footer-wrapper .rulemailer-subscriber-form input[type=checkbox] {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}
.footer-wrapper .rulemailer-subscriber-form button {
  background-color: var(--color-cta-primary);
  background-image: var(--arrow-right-url);
  background-position: right 15px center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 20px;
  color: var(--color-heading-secondary);
  cursor: pointer;
  font-family: var(--font-paragraph);
  font-size: 1.063rem;
  font-weight: 700;
  height: 40px;
  padding: 0 65px 0 20px;
  transition: 0.2s all;
}
.footer-wrapper .rulemailer-subscriber-form button:hover, .footer-wrapper .rulemailer-subscriber-form button:active {
  opacity: 0.6;
}
.footer-wrapper .rulemailer-email-check {
  display: none !important;
}
.footer-wrapper .second-logo {
  background-image: var(--logo-footer-second-url);
  background-position: bottom;
  width: 260px;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  margin-top: 60px;
  margin-left: auto;
}
.footer-wrapper #footer-form {
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: end;
}
.footer-wrapper #footer-form form {
  display: flex;
  align-items: flex-end;
  font-family: var(--font-paragraph);
  width: 100%;
  max-width: 560px;
  gap: 20px;
}
.footer-wrapper #footer-form form label {
  font-size: 0.936rem;
  flex: 1;
  position: relative;
}
.footer-wrapper #footer-form form input[type=text] {
  height: 40px;
  border: none;
  margin-top: 10px;
  width: 95%;
  border-radius: 6px;
  padding: 0 12px;
  font-size: 1.063rem;
  min-width: 250px;
}
.footer-wrapper #footer-form form input[type=submit] {
  background-image: var(--arrow-right-url);
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-color: var(--color-cta-primary);
  color: var(--color-heading-secondary);
  border: none;
  height: 40px;
  border-radius: 20px;
  padding: 0 65px 0 20px;
  font-size: 1.063rem;
  font-weight: 700;
  font-family: var(--font-paragraph);
  cursor: pointer;
}
.footer-wrapper #footer-form form input[type=submit]:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
.footer-wrapper #footer-form form .input-error {
  color: red !important;
  display: none;
  font-size: 0.875rem;
  position: absolute;
}

.sz-important-talks-in-school .footer-wrapper .rulemailer-subscriber-form,
.sz-parent-support .footer-wrapper .rulemailer-subscriber-form {
  display: none;
}

.sz-important-talks-in-school .social-media-link {
  display: none;
}

@media (max-width: 600px) {
  .footer-wrapper .footer-content-wrapper .footer-first-section {
    padding-right: 0px;
  }
  .footer-wrapper .second-logo {
    margin-left: 0;
    margin-top: 20px;
  }
  .footer-wrapper #footer-form {
    flex-direction: column;
    align-items: start;
  }
  .footer-wrapper #footer-form form {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-wrapper .rulemailer-subscriber-form input {
    width: 100%;
  }
  body.sz-main .footer-wrapper .logo {
    height: 50px;
  }
}
@media (max-width: 1000px) {
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-wrapper .logo {
    height: 100px;
  }
  .footer-wrapper .footer-content-wrapper {
    flex-direction: column;
    margin-left: 0;
  }
  .footer-wrapper .footer-content-wrapper .footer-links-wrapper {
    margin-top: 30px;
    flex-direction: column;
  }
  .footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-first-section {
    margin-right: 30px;
  }
}
body.sz-main footer {
  background: var(--color-bg-tertiary);
}
body.sz-main footer .footer-links-wrapper .footer-links-first-section a,
body.sz-main footer .footer-links-wrapper .footer-links-second-section a {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;
  color: var(--color-fg-primary) !important;
}
body.sz-main footer .footer-links-wrapper .footer-links-first-section a:hover,
body.sz-main footer .footer-links-wrapper .footer-links-second-section a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}

body.sz-important-talks-in-school footer {
  background: #fafafa;
  font-weight: 400;
}
body.sz-important-talks-in-school .footer-wrapper .logo {
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
  margin-bottom: 20px;
  width: 100%;
}
body.sz-important-talks-in-school .footer-wrapper .footer-content-wrapper {
  font-size: 14px;
}
body.sz-important-talks-in-school .footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.linkedin-link {
  display: none;
}
body.sz-important-talks-in-school .footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.facebook-link {
  display: none;
}
body.sz-important-talks-in-school .footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-first-section a,
body.sz-important-talks-in-school .footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-second-section a {
  color: #000;
  border-bottom: none;
  font-weight: 400;
}
body.sz-important-talks-in-school .footer-wrapper .second-logo {
  margin-top: 30px;
}

body.sz-parent-support footer {
  background: var(--color-bg-footer);
  font-weight: 400;
}
body.sz-parent-support .footer-wrapper .logo {
  background-repeat: no-repeat;
  background-size: contain;
  height: 70px;
  margin-bottom: 20px;
  width: 100%;
}
body.sz-parent-support .footer-wrapper .footer-content-wrapper {
  font-size: 14px;
}
body.sz-parent-support .footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.linkedin-link {
  display: none;
}
body.sz-parent-support .footer-wrapper .footer-content-wrapper .footer-first-section .social-media .social-media-link.facebook-link {
  display: none;
}
body.sz-parent-support .footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-first-section a,
body.sz-parent-support .footer-wrapper .footer-content-wrapper .footer-links-wrapper .footer-links-second-section a {
  color: #000;
  border-bottom: none;
  font-weight: 400;
}
body.sz-parent-support .footer-wrapper .second-logo {
  margin-top: 30px;
}

body > header {
  background: var(--color-bg-primary);
}

.header-content {
  display: flex;
  height: 82px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 47px;
  position: relative;
}
.header-content .logo {
  height: 51px;
  margin: 0px 50px 0px 0px;
  display: block;
  width: 130px;
  background: 0% 50%/contain no-repeat var(--logo-url);
}
.header-content .header-right {
  display: flex;
  align-items: center;
  position: relative;
}
.header-content .header-right .header-profile {
  display: flex;
  align-items: center;
}
.header-content .header-right .header-profile .profile-info {
  text-align: right;
}
.header-content .header-right .header-profile .profile-info p {
  font-family: var(--font-head-primary);
  font-size: 1.25rem;
}
.header-content .header-right .header-profile .profile-info .log-out {
  color: var(--color-cta-primary);
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: var(--font-paragraph);
  font-weight: 400;
}
.header-content .header-right .header-profile .profile-info .log-out:hover {
  cursor: pointer;
}
.header-content .header-right .header-profile .profile-info a {
  color: var(--color-cta-primary);
  text-decoration: none;
}
.header-content .secondary-logo {
  display: block;
  width: 68px;
  height: 82px;
  margin-left: 50px;
  background: 100% 50%/contain no-repeat url(/wp-content/themes/szero/assets/suicide-zero-logotype-right.svg);
}

.sz-parent-support .header-content .logo {
  margin: 0px;
}

.sz-important-talks-in-school .header-content .header-right .header-links-loggedin {
  margin-right: 50px;
}
.sz-important-talks-in-school .header-content .header-right .header-links-loggedin a {
  color: var(--color-fg-primary);
  font-size: 1.063rem;
  font-weight: 400;
  text-decoration: none;
}

.topLinks {
  height: 42px;
  display: flex;
  align-items: center;
}
.topLinks ul#menu-mainmenuloggedout {
  display: flex;
  list-style-type: none;
  align-items: center;
}
.topLinks ul#menu-mainmenuloggedout li {
  margin: 0 12px;
}
.topLinks ul#menu-mainmenuloggedout li a {
  color: var(--color-fg-primary);
  text-decoration: none;
  font-weight: 400;
  font-size: 1.063rem;
}
.topLinks ul#menu-mainmenuloggedout li.nav-btn a {
  color: var(--color-cta-primary);
  background: var(--color-bg-secondary);
  padding: 6px 10px;
  border-radius: 3px;
  display: block;
}
.topLinks ul#menu-mainmenuloggedout li.nav-btn-cta a {
  background: var(--color-cta-primary);
  color: var(--color-bg-secondary);
}
.topLinks ul#menu-mainmenuloggedout li.download-book-link {
  display: flex;
  position: relative;
  align-items: center;
}
.topLinks ul#menu-mainmenuloggedout li.download-book-link a {
  font-family: var(--font-head-primary);
  font-size: 1.25rem;
  padding-right: 70px;
  display: block;
}
.topLinks ul#menu-mainmenuloggedout li.download-book-link:after {
  content: "";
  width: 60px;
  height: 60px;
  background: var(--book-image-url);
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  right: 0;
  top: -15px;
}
.topLinks ul#menu-mainmenuloggedout li.language-link a {
  padding-left: 45px;
  background: var(--language-icon-url);
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 32px;
  line-height: 32px;
  display: block;
}

.sz-main .header-content {
  height: 80px;
  max-width: initial;
  padding: 0 47px;
  position: relative;
}
.sz-main .header-content .logo {
  height: 34px;
}
.sz-main .header-content .secondary-logo {
  display: none;
}
.sz-main .header-content .header-right {
  position: relative;
}
.sz-main .header-content .header-right .header-btn {
  display: flex;
  height: 100%;
  margin-right: 77px;
  gap: 23px;
}
.sz-main .header-content .header-right .header-btn a.menu-btn {
  padding: 0px 10px 0px 34px;
  height: 30px;
  line-height: 1.813rem;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  border-radius: 6px;
  background: var(--heart-url) left 10px center no-repeat;
  background-color: var(--color-cta-tertiary);
  font-size: 0.938rem;
}
.sz-main .header-content .header-right .header-btn button.menu-btn.swish-btn {
  background-color: white;
  color: #434746;
  border: 1px solid #434746;
  background: var(--swish-logo) left 7px center no-repeat;
  background-size: contain;
  background-size: 14px;
  border-radius: 6px;
  width: 122px;
  padding-left: 20px;
  font-weight: 700;
  cursor: pointer;
  font-size: 0.938rem;
  font-family: var(--font-paragraph);
  line-height: 1.813rem;
}
.sz-main .header-content-mobile {
  display: none;
}

@media (max-width: 600px) {
  .header-content {
    padding: 0px 30px;
    height: 64px;
  }
  .header-content .logo {
    flex: 1;
  }
  .header-content #mobile-menu {
    max-width: 90%;
  }
  .sz-main .header-content {
    flex-direction: row;
    padding: 0 24px;
  }
  .sz-main .header-content .logo {
    flex: 1;
  }
  .sz-main .header-content .header-right .header-btn {
    margin-right: 0;
    gap: clamp(8px, 3vw, 23px);
  }
  .sz-main .header-content .header-right .header-btn a.hide-mobile,
  .sz-main .header-content .header-right .header-btn button.hide-mobile {
    display: none;
  }
  .sz-main .header-content .header-right .header-btn a.show-mobile {
    margin-right: 0;
  }
  .sz-main .header-content .header-right #nav-icon {
    top: -15px;
  }
  .sz-main .header-content-mobile {
    display: flex;
    justify-content: center;
    height: 40px;
    margin-bottom: 20px;
  }
  .sz-main .header-content-mobile .header-right {
    gap: 23px;
  }
}
@media (max-width: 1000px) {
  .top-menu {
    display: none;
  }
  .header-content .header-right .header-profile {
    display: none;
  }
  .sz-parent-support .header-content .header-right {
    padding-right: 60px;
  }
  .sz-parent-support .header-content .header-right .topLinks {
    display: none;
  }
  .sz-parent-support .header-content .header-right .secondary-logo {
    height: 30px;
  }
  .sz-important-talks-in-school .header-content .header-right .header-links-loggedin {
    display: none;
  }
  .sz-important-talks-in-school .header-content .header-right .topLinks {
    display: none;
  }
  .sz-important-talks-in-school .header-content .header-right .secondary-logo {
    height: 30px;
  }
}
body > main > * {
  margin-bottom: 90px;
}

main ul, main ol {
  margin: 0.5rem 0;
  margin-left: 17px;
  font-weight: 400;
}

.show-in-mobile {
  display: none;
  margin-bottom: 0;
}

.hide-in-mobile {
  display: block;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  body > main > * {
    margin-bottom: 70px;
  }
  .show-in-mobile {
    display: block;
    margin-bottom: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
  .show-in-mobile .breadcrumb {
    margin-bottom: 10px;
  }
  .hide-in-mobile {
    display: none;
    margin-bottom: 0;
  }
}
#mobile-menu {
  width: 440px;
  z-index: 2;
  background: var(--color-mobile-menu-bg);
  position: absolute;
  right: 0px;
  top: 0px;
  border-bottom-left-radius: 24px;
  padding: 100px 60px 50px 40px;
  display: none;
  box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.3);
}
#mobile-menu ul#menu-mobilemenu,
#mobile-menu ul#menu-mobilemenuloggedout {
  flex-direction: column;
  height: auto;
  align-items: flex-start;
}
#mobile-menu ul#menu-mobilemenu li,
#mobile-menu ul#menu-mobilemenuloggedout li {
  width: 100%;
  margin: 0;
  list-style: none;
}
#mobile-menu ul#menu-mobilemenu li.target-link,
#mobile-menu ul#menu-mobilemenuloggedout li.target-link {
  background: var(--external-link-url) right center no-repeat;
  width: 100%;
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children > a,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children > a {
  order: 1;
  width: 100%;
  padding-bottom: 0px;
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children ul.sub-menu,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children ul.sub-menu {
  order: 3;
  width: 100%;
  height: 0px;
  overflow: hidden;
  list-style: none;
  line-height: 1.4rem;
  padding: 4px 0px 4px 30px;
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children ul.sub-menu li,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children ul.sub-menu li {
  margin: 2px;
  margin-left: -2px;
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children .menu-arrow,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children .menu-arrow {
  background: var(--arrow-down-url) no-repeat center var(--color-mobile-menu-bg);
  backdrop-filter: blur(5px);
  order: 2;
  height: 53px;
  order: 2;
  width: 60px;
  position: absolute;
  right: -60px;
  z-index: 10;
  border: none;
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children .menu-arrow:active, #mobile-menu ul#menu-mobilemenu li.menu-item-has-children .menu-arrow:focus,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children .menu-arrow:active,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children .menu-arrow:focus {
  border: 1px solid var(--color-mobile-menu-links);
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children.is-active > a,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children.is-active > a {
  font-weight: 700;
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children.is-active .menu-arrow,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children.is-active .menu-arrow {
  transform: rotate(180deg);
}
#mobile-menu ul#menu-mobilemenu li.menu-item-has-children.is-active ul.sub-menu,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children.is-active ul.sub-menu {
  height: auto;
}
#mobile-menu ul#menu-mobilemenu li a,
#mobile-menu ul#menu-mobilemenuloggedout li a {
  color: var(--color-mobile-menu-links);
  font-size: 1.125rem;
  line-height: 2.2rem;
  padding: 10px 0px 10px 0px;
  display: block;
}
#mobile-menu ul#menu-mobilemenu li ul.sub-menu li,
#mobile-menu ul#menu-mobilemenuloggedout li ul.sub-menu li {
  margin-bottom: 0;
}
#mobile-menu ul#menu-mobilemenu li.current-menu-item,
#mobile-menu ul#menu-mobilemenuloggedout li.current-menu-item {
  background: none;
}
#mobile-menu ul#menu-mobilemenu li.current-menu-item > a,
#mobile-menu ul#menu-mobilemenuloggedout li.current-menu-item > a {
  font-weight: 400;
}
#mobile-menu ul#menu-mobilemenu li a,
#mobile-menu ul#menu-mobilemenuloggedout li a {
  text-decoration: none;
}
#mobile-menu ul#menu-mobilemenu li.menu-divider,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-divider {
  background: var(--color-cta-primary);
  width: 100%;
  height: 2px;
  margin: 25px 0 !important;
}
#mobile-menu ul#menu-mobilemenu li.menu-divider a,
#mobile-menu ul#menu-mobilemenuloggedout li.menu-divider a {
  display: none;
}
#mobile-menu ul#menu-mobilemenu li.inactive-link,
#mobile-menu ul#menu-mobilemenuloggedout li.inactive-link {
  left: -30px;
  position: relative;
}
#mobile-menu ul#menu-mobilemenu li.inactive-link a,
#mobile-menu ul#menu-mobilemenuloggedout li.inactive-link a {
  pointer-events: none;
  cursor: default;
}
#mobile-menu.active {
  display: block;
}
#mobile-menu .log-in-out-button {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 2px solid var(--color-cta-primary);
}
#mobile-menu .log-in-out-button a {
  display: block;
  text-decoration: none;
  text-align: center;
  color: var(--color-cta-primary);
  font-weight: 400;
}

.menu-toggle {
  display: none;
}
.menu-toggle:focus {
  outline: none;
}
.menu-toggle:focus .nav-icon-box {
  outline: 2px solid blue;
  border-radius: 2px;
}

.sz-main .menu-toggle {
  display: block;
}

#nav-icon {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  margin: 0;
  right: -5px;
  top: 0px;
  position: absolute;
  z-index: 10;
}
#nav-icon .hamburger:hover,
#nav-icon .hamburger:focus,
#nav-icon .hamburger:active {
  opacity: 0.7;
}
#nav-icon .nav-icon-box {
  height: 31px;
  padding: 5px;
  width: 37px;
  display: inline-block;
  position: relative;
}
#nav-icon .nav-icon-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
#nav-icon .nav-icon-inner,
#nav-icon .nav-icon-inner::before,
#nav-icon .nav-icon-inner::after {
  width: 27px;
  height: 2px;
  border-radius: 100px;
  background-color: var(--color-mobile-menu-links);
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
#nav-icon .nav-icon-inner::before,
#nav-icon .nav-icon-inner::after {
  content: "";
  display: block;
}
#nav-icon .nav-icon-inner::before {
  top: -9px;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
#nav-icon .nav-icon-inner::after {
  bottom: -9px;
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
#nav-icon.is-active .nav-icon-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 20px;
  margin-left: 6px;
}
#nav-icon.is-active .nav-icon-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  width: 20px;
}
#nav-icon.is-active .nav-icon-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 20px;
}

.navigation-lsis-mobile {
  display: none;
  position: relative;
  padding: 0 30px;
  margin-top: 5px;
  height: 46px;
  align-items: center;
}
.navigation-lsis-mobile .header-profile .profile-info {
  text-align: right;
}
.navigation-lsis-mobile .header-profile .profile-info p {
  font-family: var(--font-head-primary);
  font-size: 1.25rem;
}
.navigation-lsis-mobile .nav-btn-cta a {
  background: var(--color-cta-primary);
  color: var(--color-bg-secondary);
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 0.938rem;
  font-weight: 400;
  text-decoration: none;
}
.navigation-lsis-mobile #nav-icon {
  right: 30px;
  top: 12px;
}
.navigation-lsis-mobile #mobile-menu {
  top: -5px;
  max-width: 90%;
}

@media (max-width: 1000px) {
  .menu-toggle {
    display: block;
  }
  .navigation-lsis-mobile {
    display: flex;
  }
  #mobile-menu ul#menu-mobilemenu li.target-link,
  #mobile-menu ul#menu-mobilemenuloggedout li.target-link {
    background-size: 24px !important;
  }
  #mobile-menu ul#menu-mobilemenu li.target-link > a,
  #mobile-menu ul#menu-mobilemenuloggedout li.target-link > a {
    font-size: 1rem;
  }
  #mobile-menu ul#menu-mobilemenu li > a,
  #mobile-menu ul#menu-mobilemenuloggedout li > a {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
  #mobile-menu ul#menu-mobilemenu li ul.sub-menu li > a,
  #mobile-menu ul#menu-mobilemenuloggedout li ul.sub-menu li > a {
    font-size: 0.938rem;
    line-height: 1.4rem;
    font-weight: 400;
  }
  #mobile-menu ul#menu-mobilemenu li.menu-item-has-children .menu-arrow,
  #mobile-menu ul#menu-mobilemenuloggedout li.menu-item-has-children .menu-arrow {
    height: 44px;
  }
}
.top-menu {
  background: var(--color-cta-primary);
}
.top-menu ul {
  display: flex;
  list-style-type: none;
  justify-content: center;
  gap: 10px;
  list-style: none;
  align-items: center;
  height: 46px;
}
.top-menu ul li {
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
}
.top-menu ul li a {
  display: block;
  height: 100%;
  font-size: 1rem;
  color: var(--color-menu-links);
  text-decoration: none;
  text-decoration-color: var(--color-cta-primary);
  line-height: 46px;
  font-weight: 700;
  padding: 0 15px;
  transition: all 0.4s;
  text-underline-offset: 6px;
  text-decoration-thickness: 0.188rem;
  text-decoration-color: var(--color-menu-links);
}
.top-menu ul li a:hover {
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 0.188rem;
  transition: all 0.4s;
}
.top-menu ul li.current_page_item > a {
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 0.188rem;
}

.sz-parent-support .top-menu ul li a {
  font-weight: 400;
  font-size: 1.063rem;
}
.sz-parent-support .top-menu ul li.current-menu-item a {
  font-weight: 600;
}

.sz-main.logged-in .top-menu ul {
  height: 42px;
}
.sz-main.logged-in .top-menu ul li.current-menu-item a {
  font-weight: 600;
  border-bottom: 3px solid var(--color-menu-links);
}
.sz-main.logged-in .top-menu ul li a {
  font-weight: 600;
  line-height: 44px;
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  margin-top: 0;
}

.sz-important-talks-in-school.logged-in .top-menu li.current-menu-item a {
  font-weight: 600;
}
.sz-important-talks-in-school.logged-in .top-menu li a {
  font-weight: 400;
}

.sz-parent-support.logged-in .top-menu ul li.current-menu-item {
  background-image: url("../wp-content/themes/szero/assets/images/sz_underline_white 1.svg");
  background-position: left bottom;
  background-repeat: no-repeat;
}
.sz-parent-support.logged-in .top-menu ul li.current-menu-item a {
  font-weight: 600;
}
.sz-parent-support.logged-in .top-menu ul li a {
  font-weight: 400;
}

.container-payment {
  margin-top: 100px;
  display: flex;
  gap: 60px;
}
.container-payment .image-payment {
  width: 200px;
  height: 210px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.container-payment .image-payment.image-statusSuccess {
  background-image: var(--illustration-stars);
}
.container-payment .image-payment.image-statusFailed {
  background-image: var(--page-404-image);
}
.container-payment .item-payment-text {
  max-width: 650px;
}
.container-payment .item-payment-text h1 {
  font-size: 2.625rem;
  line-height: 100%;
  font-family: var(--font-head-secondary);
  margin-bottom: 20px;
  color: var(--color-heading-primary);
  text-transform: uppercase;
}
.container-payment .item-payment-text p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
}
.container-payment .item-payment-text a.button {
  background: var(--color-cta-primary);
  border-radius: 23px;
  bottom: 0;
  color: var(--color-bg-primary);
  display: inline-block;
  font-family: var(--font-head-primary);
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  height: 46px;
  line-height: 2.8rem;
  margin-top: 10px;
  padding: 0 30px;
  text-decoration: none;
}
.container-payment .item-payment-text a.link {
  border-bottom: 2px solid var(--color-cta-primary);
  display: inline-block;
  color: var(--color-cta-primary);
  font-weight: 400;
  text-decoration: none;
}

@media (max-width: 800px) {
  .container-payment {
    gap: 30px;
  }
  .container-payment .image-payment {
    height: 130px;
    width: 122px;
  }
  .container-payment .item-payment-text h1 {
    font-size: 3.5rem;
  }
  .container-payment .item-payment-text h1 span {
    font-size: 2.5rem;
  }
}
@media (max-width: 600px) {
  .container-payment .image-payment {
    height: 90px;
    width: 84px;
  }
  .container-payment .item-payment-text h1 {
    font-size: 2.5rem;
  }
  .container-payment .item-payment-text h1 span {
    font-size: 2rem;
  }
  .container-payment .item-payment-text p {
    font-size: 1rem;
  }
}
#quick-swish-wrapper {
  display: none;
}

.swish-popup {
  position: fixed;
  z-index: 20000;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0 0 0 0;
  background: rgba(0, 0, 0, 0.5333333333);
  overflow: hidden;
}
.swish-popup #quick-swish-wrapper {
  width: 537px;
}
.swish-popup #quick-swish-wrapper .donate-amount {
  margin-bottom: 32px;
  margin-top: 0;
}
.swish-popup #quick-swish-wrapper .donate-amount li {
  height: 33px;
}
.swish-popup #quick-swish-wrapper .donate-amount label {
  line-height: 1.938rem;
  font-size: 1.063rem;
}
.swish-popup #quick-swish-wrapper input {
  height: 36px;
}
.swish-popup #quick-swish-wrapper .own-amount-wrapper {
  display: none;
}
.swish-popup #quick-swish-wrapper .form-content-box {
  padding: 32px 40px 40px 40px;
}
.swish-popup #quick-swish-wrapper .form-content-box h2 {
  font-family: var(--font-head-secondary);
  font-size: 3.5rem;
  line-height: 100%;
  margin-bottom: 16px;
}
.swish-popup #quick-swish-wrapper .form-content-box .info-text {
  margin-bottom: 32px;
}
.swish-popup #quick-swish-wrapper .form-content-box .close-swish-popup-wrapper {
  display: flex;
  flex-direction: row-reverse;
  height: 0;
}
.swish-popup #quick-swish-wrapper .form-content-box .close-swish-popup-wrapper #close-swish-popup {
  margin-top: -20px;
  margin-right: -25px;
  border: none;
  height: 2rem;
  width: 2rem;
  transition: all 0.2s;
  background-image: var(--close-popup-icon);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
}
.swish-popup #quick-swish-wrapper .form-content-box .choose-amount {
  margin-bottom: 12px;
}
.swish-popup #quick-swish-wrapper .form-content-box input[type=number] {
  margin-top: 12px;
  border: 2px solid var(--color-cta-primary);
}
.swish-popup #quick-swish-wrapper .form-content-box .privacy-policy-swish {
  opacity: 0.6;
  font-weight: 400;
}
.swish-popup #quick-swish-wrapper .form-content-box .privacy-policy-swish a {
  color: var(--color-cta-secondary);
  font-weight: 400;
}
.swish-popup #quick-swish-wrapper .form-content-box .submit-button-container #submit-button {
  margin: 24px 0;
}

@media (max-width: 600px) {
  .swish-popup #quick-swish-wrapper {
    width: 335px;
  }
  .swish-popup #quick-swish-wrapper .form-content-box {
    padding: 16px 24px 24px 24px;
    margin-bottom: 0;
  }
  .swish-popup #quick-swish-wrapper .form-content-box .close-swish-popup-wrapper #close-swish-popup {
    margin-right: -18px;
    margin-top: -10px;
  }
  .swish-popup #quick-swish-wrapper .form-content-box .donate-amount {
    flex-direction: column;
    gap: 12px;
  }
  .swish-popup #quick-swish-wrapper .form-content-box .donate-amount li {
    flex: auto;
  }
}
.school-order-success-container {
  margin-top: 100px;
  display: flex;
  gap: 60px;
}
.school-order-success-container .school-order-success-image {
  background-image: var(--high-five);
  width: 200px;
  height: 210px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.school-order-success-container .school-order-success-text {
  max-width: 650px;
}
.school-order-success-container .school-order-success-text h1 {
  font-size: 1.8rem;
  line-height: 140%;
  font-family: var(--font-head-secondary);
  margin-bottom: 20px;
  color: var(--color-heading-primary);
  text-transform: uppercase;
}
.school-order-success-container .school-order-success-text h1 span {
  display: block;
  font-size: 1.625rem;
  line-height: 100%;
  margin-top: 26px;
}
.school-order-success-container .school-order-success-text p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
}
.school-order-success-container .school-order-success-text a.contact-link {
  color: var(--color-cta-primary);
  font-weight: 700;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}
.school-order-success-container .school-order-success-text a.login {
  background: var(--color-cta-primary);
  border-radius: 23px;
  bottom: 0;
  color: var(--color-bg-primary);
  display: inline-block;
  font-family: var(--font-head-primary);
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  height: 46px;
  line-height: 2.8rem;
  margin-top: 10px;
  padding: 0 30px;
  text-decoration: none;
}

@media (max-width: 600px) {
  .school-order-success-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 0;
  }
  .school-order-success-container .school-order-success-text h1 {
    font-size: 1.8rem;
  }
}
.signup-failed-container {
  margin-top: 100px;
  display: flex;
  gap: 60px;
}
.signup-failed-container .signup-failed-image {
  background-image: var(--high-five);
  width: 200px;
  height: 210px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.signup-failed-container .signup-failed-text {
  max-width: 650px;
}
.signup-failed-container .signup-failed-text h1 {
  font-size: 1.8rem;
  line-height: 140%;
  font-family: var(--font-head-secondary);
  margin-bottom: 20px;
  color: var(--color-heading-primary);
  text-transform: uppercase;
}
.signup-failed-container .signup-failed-text h1 span {
  display: block;
  font-size: 1.625rem;
  line-height: 100%;
  margin-top: 26px;
}
.signup-failed-container .signup-failed-text p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
}
.signup-failed-container .signup-failed-text a.contact-link {
  color: var(--color-cta-primary);
  font-weight: 700;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}
.signup-failed-container .signup-failed-text a.login {
  background: var(--color-cta-primary);
  border-radius: 23px;
  bottom: 0;
  color: var(--color-bg-primary);
  display: inline-block;
  font-family: var(--font-head-primary);
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  height: 46px;
  line-height: 2.8rem;
  margin-top: 10px;
  padding: 0 30px;
  text-decoration: none;
}

@media (max-width: 600px) {
  .signup-failed-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 0;
  }
  .signup-failed-container .signup-failed-text h1 {
    font-size: 1.8rem;
  }
}
.signup-idrottshoppet-success-container {
  margin-top: 100px;
  display: flex;
  gap: 60px;
}
.signup-idrottshoppet-success-container .signup-idrottshoppet-success-image {
  background-image: var(--illustration-stars);
  width: 200px;
  height: 210px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.signup-idrottshoppet-success-container .signup-idrottshoppet-success-text {
  max-width: 650px;
}
.signup-idrottshoppet-success-container .signup-idrottshoppet-success-text h1 {
  font-size: 2.625rem;
  line-height: 140%;
  font-family: var(--font-head-secondary);
  margin-bottom: 20px;
  color: var(--color-heading-primary);
  text-transform: uppercase;
}
.signup-idrottshoppet-success-container .signup-idrottshoppet-success-text h1 span {
  display: block;
  font-size: 1.625rem;
  line-height: 100%;
  margin-top: 26px;
}
.signup-idrottshoppet-success-container .signup-idrottshoppet-success-text p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
}
.signup-idrottshoppet-success-container .signup-idrottshoppet-success-text a {
  background: var(--color-cta-primary);
  border-radius: 23px;
  bottom: 0;
  color: var(--color-bg-primary);
  display: inline-block;
  font-family: var(--font-head-primary);
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  height: 46px;
  line-height: 2.8rem;
  margin-top: 10px;
  padding: 0 30px;
  text-decoration: none;
}

@media (max-width: 600px) {
  .signup-idrottshoppet-success-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 0;
  }
  .signup-idrottshoppet-success-container .signup-idrottshoppet-success-text h1 {
    font-size: 1.8rem;
  }
}
/*
 * Common
 *
 */
body {
  font-size: 16px;
  font-weight: 200;
  font-style: normal;
  line-height: 1.375rem;
}

body.accessibility-level-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
  word-spacing: 1.1rem;
}

body.accessibility-level-2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 3rem;
  word-spacing: 2rem;
  text-decoration: underline #cccccc;
}
body.accessibility-level-2 ::first-letter {
  font-weight: 700;
}

a.accessibility-skip-to-main-content {
  position: absolute;
  display: block;
  width: 20%;
  min-width: 200px;
  padding: 20px;
  margin: 0px 50%;
  color: var(--color-fg-primary);
  background: var(--color-bg-primary);
  border: 3px solid var(--color-fg-secondary);
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.1s linear;
  transform: translateY(-100%) translateX(-50%);
  opacity: 0;
  z-index: 1000;
}
a.accessibility-skip-to-main-content:focus {
  transform: translateY(100px) translateX(-50%);
  opacity: 1;
}

body {
  line-break: normal;
  word-break: break-word;
}

.hover:hover {
  opacity: 0.6;
  transition: all 0.2s;
}

body.sz-important-talks-in-school .active-link:active, body.sz-parent-support .active-link:active {
  opacity: 1;
  color: var(--color-cta-primary-active) !important;
}

body.sz-main .active-link:active {
  opacity: 1;
  color: var(--color-cta-primary-active) !important;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.active-btn:active {
  opacity: 1;
  background-color: var(--color-cta-primary-active) !important;
}

.active-btn.strong:active {
  opacity: 1;
  background-color: var(--color-cta-strong-active) !important;
}

@font-face {
  font-family: "Inter";
  src: url("../wp-content/themes/szero/assets/fonts/Inter-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../wp-content/themes/szero/assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../wp-content/themes/szero/assets/fonts/Inter-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../wp-content/themes/szero/assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Livsviktigskisstypo";
  src: url("../wp-content/themes/szero/assets/fonts/Livsviktigskisstypo.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "questa-slab";
  src: url("../wp-content/themes/szero/assets/fonts/QuestaSlab-medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "questa-slab";
  src: url("../wp-content/themes/szero/assets/fonts/QuestaSlab-regular-italic.otf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Hackney-Vector";
  src: url("../wp-content/themes/szero/assets/fonts/Hackney-Vector.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "circular";
  src: url("../wp-content/themes/szero/assets/fonts/circular-book.ttf");
  font-weight: 450;
  font-style: normal;
}
@font-face {
  font-family: "circular";
  src: url("../wp-content/themes/szero/assets/fonts/circular-bold.ttf");
  font-weight: 700;
  font-style: normal;
}
:root {
  font-size: 16px;
}

body {
  font-family: var(--font-paragraph);
  line-height: 1.375rem;
  font-weight: 200;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-head-primary);
}

/*
 * Sections
 *
 */
.value-blocks-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.value-blocks-wrapper .value-block {
  flex: 0 0 49%;
  background-color: var(--color-bg-secondary);
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 25px 55px;
  font-size: 1.1rem;
  font-weight: 400;
}
.value-blocks-wrapper .value-block .heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.value-blocks-wrapper .value-block .heading-wrapper h3 {
  font-family: var(--font-head-secondary);
  text-transform: uppercase;
  color: var(--color-heading-primary);
  line-height: 2rem;
}
.value-blocks-wrapper .value-block .heading-wrapper .icon {
  height: 78px;
  padding-right: 12px;
}
.value-blocks-wrapper .text {
  margin-bottom: 12px;
  font-size: 1rem;
}
.value-blocks-wrapper .link {
  text-decoration: none;
  color: var(--color-fg-secondary);
  font-size: 1.063rem;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

body.sz-important-talks-in-school .value-blocks-wrapper .value-block {
  padding: 35px 55px 25px 55px;
}

body.sz-main .value-blocks-wrapper .value-block .heading-wrapper h3 {
  text-transform: none;
  font-family: var(--font-head-primary);
  font-size: 1.625rem;
  color: var(--color-heading-tertiary);
}
body.sz-main .value-blocks-wrapper .value-block .text {
  font-size: 1rem;
}
body.sz-main .value-blocks-wrapper .value-block .link {
  color: var(--color-cta-secondary);
  display: block;
  width: fit-content;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  font-weight: 700;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .value-blocks-wrapper {
    flex-direction: column;
  }
  .value-blocks-wrapper .value-block {
    font-size: 1rem;
    padding: 22px 30px;
  }
  .value-blocks-wrapper .value-block .heading-wrapper h3 {
    font-size: 1.1rem;
  }
  .value-blocks-wrapper .value-block .heading-wrapper .icon {
    height: 58px;
    padding-right: 12px;
  }
  body.sz-main .value-blocks-wrapper .value-block .heading-wrapper h3 {
    font-size: 1.5rem;
  }
}
.campaign-wrapper a {
  text-decoration: none;
  color: var(--color-cta-secondary);
}
.campaign-wrapper a .background-image {
  background-image: var(--paper-background);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--color-bg-secondary);
  border-radius: 6px;
  mix-blend-mode: multiply;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.campaign-wrapper a .background-image .image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  margin-right: 40px;
  aspect-ratio: 1.5/1;
  border-radius: 6px;
}
.campaign-wrapper a .background-image .text-wrapper {
  max-width: 60%;
  font-weight: 400;
}
.campaign-wrapper a .background-image .text-wrapper h2 {
  font-family: var(--font-head-secondary);
  font-size: 3.5rem;
  line-height: 100%;
  margin-bottom: 12px;
}
.campaign-wrapper a .background-image .text-wrapper p {
  margin-bottom: 12px;
  font-size: 1.125rem;
}
.campaign-wrapper a .background-image .text-wrapper .link-text {
  color: var(--color-cta-primary);
  font-weight: 700;
  line-height: 140%;
  font-size: 1.125rem;
  width: fit-content;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

body.sz-important-talks-in-school .campaign-wrapper .background-image, body.sz-parent-support .campaign-wrapper .background-image {
  background-color: var(--color-bg-secondary);
}
body.sz-important-talks-in-school .campaign-wrapper .background-image .text-wrapper, body.sz-parent-support .campaign-wrapper .background-image .text-wrapper {
  color: #000;
}
body.sz-important-talks-in-school .campaign-wrapper .background-image .text-wrapper h2, body.sz-parent-support .campaign-wrapper .background-image .text-wrapper h2 {
  text-transform: uppercase;
  font-size: 1.9rem;
  line-height: 130%;
  color: var(--color-heading-primary);
}

@media (max-width: 1000px) {
  .campaign-wrapper a .background-image {
    padding: 40px 40px;
  }
  .campaign-wrapper a .background-image .text-wrapper {
    max-width: 50%;
  }
  .campaign-wrapper a .background-image .text-wrapper h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 600px) {
  .campaign-wrapper a .background-image {
    flex-direction: column;
    padding: 25px;
  }
  .campaign-wrapper a .background-image .image {
    margin: 0;
    aspect-ratio: 1.8/1;
  }
  .campaign-wrapper a .background-image .text-wrapper {
    max-width: 100%;
  }
  .campaign-wrapper a .background-image .text-wrapper h2 {
    font-size: 2rem;
    margin-top: 17px;
  }
  .campaign-wrapper a .background-image .text-wrapper p, .campaign-wrapper a .background-image .text-wrapper a {
    font-size: 0.938rem;
  }
  body.sz-important-talks-in-school .campaign-wrapper .background-image .text-wrapper h2, body.sz-parent-support .campaign-wrapper .background-image .text-wrapper h2 {
    font-size: 1.5rem;
    line-height: 130%;
  }
}
.column-img-heading-link-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 60px 30px;
  font-weight: 400;
}
.column-img-heading-link-wrapper .column {
  flex: 0 0 31%;
}
.column-img-heading-link-wrapper .column .image {
  border-radius: 6px;
  width: 100%;
  aspect-ratio: 2/1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 16px;
}
.column-img-heading-link-wrapper .column .heading {
  font-family: var(--font-paragraph);
  font-size: 1.1rem;
  margin-bottom: 14px;
}
.column-img-heading-link-wrapper .column .link {
  color: var(--color-fg-secondary);
  font-weight: 600;
  font-size: 1rem;
}
.column-img-heading-link-wrapper .column p {
  margin-bottom: 14px;
}
.column-img-heading-link-wrapper .column a {
  color: var(--color-cta-primary);
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid var(--color-cta-primary);
}

body.sz-main .column-img-heading-link-wrapper .column link,
body.sz-main .column-img-heading-link-wrapper .column a {
  color: var(--color-cta-primary);
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid var(--color-cta-primary);
}
body.sz-main .column-img-heading-link-wrapper .column link:hover,
body.sz-main .column-img-heading-link-wrapper .column a:hover {
  opacity: 0.6;
}

@media (max-width: 1000px) {
  .column-img-heading-link-wrapper .column {
    flex: 0 0 47%;
  }
}
@media (max-width: 600px) {
  .column-img-heading-link-wrapper {
    gap: 40px;
  }
  .column-img-heading-link-wrapper .column {
    flex: 0 0 100%;
  }
  .column-img-heading-link-wrapper .column .image {
    width: inherit;
  }
  .column-img-heading-link-wrapper .column .heading {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .column-img-heading-link-wrapper .column .link {
    font-size: 0.9rem;
  }
}
.columns-with-links-on-subpages-section .columns {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  margin-bottom: 30px;
}
@media (max-width: 1000px) {
  .columns-with-links-on-subpages-section .columns {
    flex-direction: column;
  }
}
.columns-with-links-on-subpages-section .columns > * {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 28px;
  background-color: var(--color-bg-secondary);
  border-radius: 8px;
}
.columns-with-links-on-subpages-section .columns > * .image {
  width: 100%;
  aspect-ratio: 5/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 20px;
  border-radius: 6px;
}
.columns-with-links-on-subpages-section .columns > * a h3 {
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.8rem;
  margin-bottom: 1.1rem;
  color: var(--color-fg-primary);
  font-family: var(--font-head-primary);
}
.columns-with-links-on-subpages-section .columns > * a {
  text-decoration: none;
  font-weight: 400;
  line-height: 1.6rem;
  color: var(--color-fg-primary);
  font-family: var(--font-paragraph);
}
.columns-with-links-on-subpages-section .columns > * .text {
  line-height: 140%;
}

body.sz-main .columns-with-links-on-subpages-section .columns > * a h3 {
  font-weight: 700;
}

.sz-important-talks-in-school .columns > * a h3,
.sz-parent-support .columns > * a h3 {
  font-size: 1.375rem;
}
.sz-important-talks-in-school .columns > * .text,
.sz-parent-support .columns > * .text {
  font-size: 1rem;
}

.columns-with-links-to-practices-section {
  text-align: center;
}
.columns-with-links-to-practices-section > h3 {
  text-align: initial;
  font-family: var(--font-head-secondary);
  font-size: var(--font-size-headline);
  color: var(--color-fg-tertiary);
  padding: 4px 0px 8px 0px;
  line-height: 2.5rem;
  text-transform: uppercase;
}
.columns-with-links-to-practices-section .columns {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  margin-bottom: 30px;
}
@media (max-width: 800px) {
  .columns-with-links-to-practices-section .columns {
    flex-direction: column;
  }
}
.columns-with-links-to-practices-section .columns > * {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}
.columns-with-links-to-practices-section .columns > * .image {
  width: 100%;
  aspect-ratio: 1.5/1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 21px;
  border-radius: 6px;
}
.columns-with-links-to-practices-section .columns > * a {
  text-decoration: none;
  font-weight: 500;
  color: var(--color-fg-primary);
  font-family: var(--font-head-primary);
}
.columns-with-links-to-practices-section .link-to-see-all {
  color: var(--color-fg-tertiary);
  font-family: var(--font-head-primary);
  display: block;
  margin: 0 auto;
  text-decoration: none;
  font-style: italic;
  width: fit-content;
}

body.sz-main .columns-with-links-to-practices-section > h3 {
  font-family: var(--font-head-primary);
  color: var(--color-heading-tertiary);
  font-size: 1.625rem;
  text-transform: none;
  line-height: 125%;
}
body.sz-main .columns-with-links-to-practices-section .columns > * a {
  position: relative;
}
body.sz-main .columns-with-links-to-practices-section .columns > * a .image {
  border-radius: 6px;
}
body.sz-main .columns-with-links-to-practices-section .columns > * a .text {
  position: absolute;
  bottom: 40px;
  background-color: #fff;
  width: 100%;
  padding: 5px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 130%;
  max-height: 40px;
  overflow: hidden;
  white-space: nowrap;
}
body.sz-main .columns-with-links-to-practices-section .link-to-see-all {
  font-style: normal;
  border-bottom: 2px solid var(--color-cta-primary);
  font-weight: 700;
  font-size: 1.125rem;
}
body.sz-main .columns-with-links-to-practices-section .link-to-see-all:active {
  opacity: 1;
  color: var(--color-cta-primary-active) !important;
  border-bottom: 2px solid var(--color-cta-primary-active) !important;
}
@media (max-width: 1000px) {
  body.sz-main .columns-with-links-to-practices-section .columns {
    gap: 20px;
  }
  body.sz-main .columns-with-links-to-practices-section .columns > * a .text {
    font-size: 1.125rem;
  }
}
@media (max-width: 600px) {
  body.sz-main .columns-with-links-to-practices-section .columns > * a .text {
    font-size: 1rem;
  }
  body.sz-main .columns-with-links-to-practices-section > h3 {
    font-size: 1.5rem;
  }
}

.columns-with-text-and-button-section {
  display: flex;
  flex: 1;
  gap: 30px;
}
.columns-with-text-and-button-section .columns-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.columns-with-text-and-button-section .columns-item.column-item-image-bottom .column-image {
  order: 2;
}
.columns-with-text-and-button-section .columns-item.column-item-image-bottom .column-content {
  order: 1;
}
.columns-with-text-and-button-section .columns-item.column-item-image-bottom .column-image {
  margin-top: 24px;
}
.columns-with-text-and-button-section .columns-item.column-item-image-top .column-image {
  order: 1;
}
.columns-with-text-and-button-section .columns-item.column-item-image-top .column-content {
  order: 2;
}
.columns-with-text-and-button-section .columns-item.column-item-image-top .column-image {
  margin-bottom: 24px;
}
.columns-with-text-and-button-section .columns-item .column-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.columns-with-text-and-button-section .columns-item .column-content.center-content {
  text-align: center;
}
.columns-with-text-and-button-section .columns-item .column-content.center-content a.column-link {
  margin: 0 auto;
}
.columns-with-text-and-button-section .columns-item .column-content h3 {
  margin-bottom: 15px;
}
.columns-with-text-and-button-section .columns-item .column-content h3.lsis {
  font-family: var(--font-head-secondary);
  color: var(--color-heading-secondary);
  line-height: 2.125rem;
  font-size: 1.313rem;
  text-transform: uppercase;
}
.columns-with-text-and-button-section .columns-item .column-content h3.vanlig, .columns-with-text-and-button-section .columns-item .column-content h3.h3-regular {
  line-height: 2.5rem;
  font-size: 1.5rem;
  font-weight: 500;
}
.columns-with-text-and-button-section .columns-item .column-content .column-text {
  flex: 1;
}
.columns-with-text-and-button-section .columns-item .column-content .column-text p {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 140%;
  width: fit-content;
}
.columns-with-text-and-button-section .columns-item .column-content .column-text a {
  color: var(--color-cta-primary);
  text-decoration: none;
}
.columns-with-text-and-button-section .columns-item .column-content .column-text ol, .columns-with-text-and-button-section .columns-item .column-content .column-text li {
  font-weight: 400;
  font-size: 1.125rem;
}
.columns-with-text-and-button-section .columns-item .column-image {
  width: 100%;
  aspect-ratio: 1.2/1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px;
}
.columns-with-text-and-button-section .columns-item a.column-link {
  margin-top: 10px;
  background: var(--color-cta-primary);
  height: 46px;
  line-height: 2.8rem;
  border-radius: 23px;
  display: inline-block;
  padding: 0 30px;
  text-decoration: none;
  color: var(--color-bg-primary);
  font-family: var(--font-head-primary);
  font-weight: 400;
  font-style: italic;
  font-size: 1.25rem;
  width: fit-content;
  max-width: 80%;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .columns-with-text-and-button-section {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0px;
    margin-bottom: 30px;
  }
  .columns-with-text-and-button-section .columns-item {
    width: 100%;
    max-width: 40%;
    flex: auto;
    margin-bottom: 40px;
  }
  .columns-with-text-and-button-section .columns-item .divider {
    height: 1px;
    width: 100%;
    background: #000;
    margin-top: 40px;
  }
  .columns-with-text-and-button-section .columns-item .column-image {
    width: 100%;
  }
  .columns-with-text-and-button-section .columns-item a.column-link {
    position: inherit;
  }
}
@media (max-width: 600px) {
  .columns-with-text-and-button-section .columns-item {
    max-width: 100%;
  }
  .columns-with-text-and-button-section .columns-item.column-item-image-bottom .column-image {
    order: 1;
  }
  .columns-with-text-and-button-section .columns-item.column-item-image-bottom .column-content {
    order: 2;
  }
}
body.sz-main .columns-with-text-and-button-section .columns-item .column-content h3.lsis {
  color: var(--color-heading-tertiary);
  font-size: 1.375rem;
  line-height: 100%;
}
body.sz-main .columns-with-text-and-button-section .columns-item .column-content h3.vanlig {
  font-size: 1.25rem;
  line-height: 100%;
  font-weight: 700;
}
body.sz-main .columns-with-text-and-button-section .columns-item .column-content .column-text p, body.sz-main .columns-with-text-and-button-section .columns-item .column-content .column-text ol, body.sz-main .columns-with-text-and-button-section .columns-item .column-content .column-text li {
  font-size: 1rem;
}
body.sz-main .columns-with-text-and-button-section .columns-item .column-content .column-text a {
  border-bottom: 3px solid var(--color-cta-primary);
  font-weight: 700;
}
body.sz-main .columns-with-text-and-button-section .columns-item .column-content .column-text a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
body.sz-main .columns-with-text-and-button-section .columns-item .column-content .column-text a:active {
  opacity: 1;
  color: var(--color-cta-primary-active) !important;
  border-bottom: 3px solid var(--color-cta-primary-active) !important;
}
body.sz-main .columns-with-text-and-button-section .columns-item a.column-link {
  font-style: normal;
  font-size: 1.125rem;
  height: auto;
  max-height: 40px;
  line-height: 100%;
  padding: 10px 24px;
  font-weight: 700;
}

.column-wiggly-border-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 14px;
}
.column-wiggly-border-wrapper .column {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  color: var(--color-fg-secondary);
}
.column-wiggly-border-wrapper .column h4 {
  font-family: var(--font-paragraph);
  font-weight: 600;
  font-size: 1.12rem;
  text-align: center;
}
.column-wiggly-border-wrapper .column p {
  font-weight: 400;
  font-size: 0.87rem;
  text-align: center;
}

@media (max-width: 600px) {
  .column-wiggly-border-wrapper {
    columns: 2;
    gap: 4px 4px;
  }
  .column-wiggly-border-wrapper .column {
    flex-basis: 48%;
  }
}
.divider-with-text-and-buttons-section-wrapper {
  display: flex;
  align-items: center;
  padding: 60px 20px;
}
.divider-with-text-and-buttons-section-wrapper .full-width-background {
  background: var(--color-bg-tertiary);
}
.divider-with-text-and-buttons-section-wrapper .first-section {
  display: flex;
  width: 60%;
  align-items: center;
}
.divider-with-text-and-buttons-section-wrapper .first-section .illustration {
  margin-right: 50px;
  max-width: 35%;
}
.divider-with-text-and-buttons-section-wrapper .first-section .text-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  font-weight: 400;
}
.divider-with-text-and-buttons-section-wrapper .first-section .text-wrapper h3 {
  font-size: 1.625rem;
  margin-bottom: 12px;
  line-height: 125%;
  font-weight: 400;
}
.divider-with-text-and-buttons-section-wrapper .second-section {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper {
  display: flex;
  gap: 40px;
}
.divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper .strong {
  background: var(--color-cta-tertiary);
}
.divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper .primary {
  background: var(--color-cta-primary);
}
.divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper .strong,
.divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper .primary {
  color: #fff;
  padding: 10px 24px;
  border-radius: 55px;
  border: none;
  font-size: 1.063rem;
  font-weight: 700;
  text-decoration: none;
  max-height: 40px;
  overflow: hidden;
}
.divider-with-text-and-buttons-section-wrapper .second-section .link {
  color: var(--color-cta-primary);
  font-weight: 700;
  font-size: 1.125rem;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  line-height: 160%;
}
.divider-with-text-and-buttons-section-wrapper .second-section a {
  margin-top: 20px;
}

.divider-with-text-and-buttons-section-wrapper.no-links {
  justify-content: center;
}
.divider-with-text-and-buttons-section-wrapper.no-links .second-section {
  display: none;
}

body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper.single-button .first-section {
  width: 70%;
}
body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper.single-button .second-section {
  width: 30%;
}
body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper {
  font-style: italic;
  line-height: 100%;
  font-family: var(--font-head-primary);
}
body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper a {
  margin-top: 0;
}
body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper .strong {
  background: var(--color-cta-tertiary);
  font-weight: 400;
  font-size: 1.25rem;
  max-height: none;
  text-align: center;
}
body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper .primary {
  background: var(--color-bg-primary);
  color: var(--color-cta-primary);
  border: 1px solid var(--color-cta-primary);
  font-weight: 400;
  font-size: 1.25rem;
  max-height: none;
  text-align: center;
}
body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper .second-section .link {
  font-weight: 400;
}

body.sz-parent-support .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper {
  font-style: italic;
  line-height: 100%;
  font-family: var(--font-head-primary);
}
body.sz-parent-support .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper .strong {
  background: var(--color-cta-tertiary);
  font-weight: 400;
}
body.sz-parent-support .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper .primary {
  background: var(--color-bg-primary);
  color: var(--color-cta-primary);
  border: 1px solid var(--color-cta-primary);
  font-weight: 400;
}
body.sz-parent-support .divider-with-text-and-buttons-section-wrapper .second-section .link {
  font-weight: 400;
}

body.sz-main .divider-with-text-and-buttons-section-wrapper .first-section h3 {
  font-weight: 700;
}
body.sz-main .divider-with-text-and-buttons-section-wrapper .second-section .link {
  color: var(--color-cta-secondary);
}

@media (max-width: 1000px) {
  .divider-with-text-and-buttons-section-wrapper {
    flex-direction: column;
    padding: 40px 0px;
  }
  .divider-with-text-and-buttons-section-wrapper .first-section {
    flex-direction: column;
    width: 100%;
  }
  .divider-with-text-and-buttons-section-wrapper .first-section .illustration {
    margin-right: auto;
    margin-bottom: 20px;
  }
  .divider-with-text-and-buttons-section-wrapper .first-section .text-wrapper {
    margin-bottom: 20px;
  }
  .divider-with-text-and-buttons-section-wrapper .second-section {
    flex-direction: column;
    width: 100%;
  }
  .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 20px;
    text-align: center;
  }
  body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper.single-button .first-section {
    width: 100%;
  }
  body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper.single-button .second-section {
    width: 100%;
  }
  body.sz-important-talks-in-school .divider-with-text-and-buttons-section-wrapper .second-section .btn-wrapper a {
    margin-top: 20px;
  }
  body.sz-main .divider-with-text-and-buttons-section-wrapper .first-section .text-wrapper h3 {
    line-height: 120%;
  }
}
.facts-wrapper .background-image {
  background-image: var(--dotted-background);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  mix-blend-mode: multiply;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
}
.facts-wrapper .background-image h2 {
  font-family: var(--font-head-secondary);
  text-align: center;
  font-size: 3.5rem;
  line-height: 100%;
  margin-bottom: 12px;
  width: 100%;
}
.facts-wrapper .background-image .column-wrapper {
  display: flex;
  flex-direction: row;
}
.facts-wrapper .background-image .column-wrapper .column {
  background-color: var(--color-bg-secondary);
  margin: 25px;
  padding: 25px;
  width: 100%;
  border-radius: 6px;
  font-weight: 400;
}
.facts-wrapper .background-image .column-wrapper .column h3 {
  margin-bottom: 5px;
  font-family: var(--font-paragraph);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 130%;
}
.facts-wrapper .background-image .column-wrapper .column ul {
  margin-left: 17px;
}

body.sz-important-talks-in-school .facts-wrapper, body.sz-parent-support .facts-wrapper {
  background-color: var(--color-bg-secondary);
}
body.sz-important-talks-in-school .facts-wrapper .background-image h2, body.sz-parent-support .facts-wrapper .background-image h2 {
  text-transform: uppercase;
  font-size: 1.9rem;
}

@media (max-width: 1000px) {
  .facts-wrapper .background-image {
    padding: 20px;
  }
  .facts-wrapper .background-image .column-wrapper {
    flex-direction: column;
  }
  .facts-wrapper .background-image .column-wrapper .column {
    margin: 15px;
    width: auto;
  }
  body.sz-important-talks-in-school .facts-wrapper .background-image h2, body.sz-parent-support .facts-wrapper .background-image h2 {
    font-size: 1.3rem;
    line-height: 140%;
    margin-bottom: 0px;
    padding: 12px;
  }
}
@media (max-width: 600px) {
  .facts-wrapper .background-image {
    padding: 0px 22px;
  }
  .facts-wrapper .background-image h2 {
    font-size: 2.625rem;
    margin-bottom: 41px;
    margin-top: 36px;
  }
}
.foldout-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.foldout-list .foldout-item {
  margin-bottom: 12px;
  border-radius: 6px;
  overflow: hidden;
}
.foldout-list .foldout-item .foldout-item-heading {
  cursor: pointer;
  position: relative;
  background-color: var(--color-bg-tertiary);
  padding: 15px 60px 15px 20px;
  line-height: 140%;
  font-size: 1.125rem;
}
.foldout-list .foldout-item .foldout-item-heading span.arrow {
  background-image: var(--arrow-down-url);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  height: 22px;
  width: 22px;
  right: 20px;
  top: 50%;
  margin-top: -11px;
}
.foldout-list .foldout-item .foldout-item-text {
  position: relative;
  background-color: var(--color-bg-tertiary);
  height: 0px;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 140%;
}
.foldout-list .foldout-item .foldout-item-text.animating {
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -o-transition: height 0.5s;
  transition: height 0.5s;
}
.foldout-list .foldout-item .foldout-item-text .foldout-item-text-inner {
  padding: 15px 40px 20px 20px;
}
.foldout-list .foldout-item .foldout-item-text .foldout-item-text-inner a {
  color: var(--color-cta-secondary);
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
  transition: all 0.3s;
}
.foldout-list .foldout-item .foldout-item-text .foldout-item-text-inner a:hover {
  opacity: 0.6;
  transition: all 0.3s;
}
.foldout-list .foldout-item.marked {
  margin-bottom: 24px;
}
.foldout-list .foldout-item.marked .foldout-item-heading {
  background-color: var(--color-bg-quaternary);
}
.foldout-list .foldout-item.marked .foldout-item-heading span.arrow {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
.foldout-list .foldout-item.marked .foldout-item-text {
  height: auto;
}

@media (max-width: 600px) {
  .foldout-list .foldout-item .foldout-item-heading {
    padding: 15px 50px 15px 15px;
    line-height: 140%;
    font-size: 1rem;
  }
  .foldout-list .foldout-item .foldout-item-heading span.arrow {
    right: 15px;
  }
  .foldout-list .foldout-item .foldout-item-text {
    font-size: 1rem;
    line-height: 140%;
  }
}
.iframe-section {
  display: flex;
  justify-content: center;
}
.iframe-section iframe {
  border: none;
  max-width: 100%;
}

.grid-with-image-or-text-with-background-color-wrapper {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.grid-with-image-or-text-with-background-color-wrapper h3 {
  font-family: var(--font-head-secondary);
  color: var(--color-cta-primary);
  text-transform: uppercase;
  margin-bottom: 18px;
  font-size: 1.31rem;
  line-height: 2.16rem;
}
.grid-with-image-or-text-with-background-color-wrapper h4, .grid-with-image-or-text-with-background-color-wrapper p {
  font-family: var(--font-paragraph);
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 400;
}
.grid-with-image-or-text-with-background-color-wrapper p {
  font-size: 1.125rem;
}
.grid-with-image-or-text-with-background-color-wrapper h4 {
  margin-bottom: 18px;
}
.grid-with-image-or-text-with-background-color-wrapper .column {
  width: 50%;
  padding: 40px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  font-weight: 400;
}
.grid-with-image-or-text-with-background-color-wrapper .primary-background {
  background-color: var(--color-bg-tertiary);
}
.grid-with-image-or-text-with-background-color-wrapper .secondary-background {
  background-color: var(--color-bg-secondary);
}
.grid-with-image-or-text-with-background-color-wrapper .image {
  aspect-ratio: 1/1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50%;
  margin: 0 auto;
  border-radius: 6px;
}

body.sz-main .grid-with-image-or-text-with-background-color-wrapper h3 {
  text-transform: none;
  font-family: var(--font-head-primary);
  color: var(--color-heading-tertiary);
  font-size: 1.625rem;
  margin-bottom: 5px;
}
body.sz-main .grid-with-image-or-text-with-background-color-wrapper h4 {
  margin-bottom: 5px;
}
body.sz-main .grid-with-image-or-text-with-background-color-wrapper p {
  line-height: 140%;
}

@media (max-width: 600px) {
  .grid-with-image-or-text-with-background-color-wrapper {
    flex-direction: column;
  }
  .grid-with-image-or-text-with-background-color-wrapper .column {
    width: 100%;
    padding: 20px;
    height: auto;
    margin-bottom: 20px;
  }
  .grid-with-image-or-text-with-background-color-wrapper .image {
    width: 75%;
  }
  body.sz-main .grid-with-image-or-text-with-background-color-wrapper h3 {
    font-size: 1.5rem;
  }
}
@media (max-width: 1000px) {
  .grid-with-image-or-text-with-background-color-wrapper h3 {
    font-size: 1rem;
    line-height: 1.8rem;
  }
  .grid-with-image-or-text-with-background-color-wrapper h4, .grid-with-image-or-text-with-background-color-wrapper p {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
  }
  .grid-with-image-or-text-with-background-color-wrapper .column {
    height: auto;
    padding: 30px;
  }
}
.image-section {
  width: 100%;
}

.interactive-exercises-wrapper p {
  text-align: center;
  margin-top: 20px;
}
.interactive-exercises-wrapper video {
  max-width: 100%;
}

.lsis-heading {
  font-family: var(--font-head-secondary);
  color: var(--color-fg-tertiary);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 180%;
  margin-bottom: 45px;
}

.regular-heading {
  color: var(--color-heading-tertiary);
  font-family: var(--font-head-primary);
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 5px;
  line-height: 1.8rem;
  margin-bottom: 45px;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

body.sz-main .lsis-heading {
  font-size: 3.625rem;
  line-height: 100%;
  color: var(--color-heading-tertiary);
}
body.sz-main .regular-heading {
  font-size: 3rem;
  line-height: 100%;
}

body.sz-important-talks-in-school .lsis-heading {
  margin-bottom: 25px;
}

@media (max-width: 600px) {
  body.sz-main .lsis-heading {
    font-size: 2.25rem;
  }
  body.sz-main .regular-heading {
    font-size: 2rem;
    line-height: 120%;
  }
}
section.map-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 40px;
  overflow: hidden;
}
section.map-section div.search {
  flex: 0 100%;
  position: relative;
}
section.map-section div.search div.search-input-container {
  display: inline-block;
  position: relative;
  width: 300px;
}
section.map-section div.search div.search-input-container input {
  border: 1px solid var(--color-cta-primary);
  border-radius: 6px;
  font-family: var(--font-paragraph);
  font-size: 1rem;
  height: 2rem;
  width: 100%;
  max-width: 100%;
  padding: 0 18px;
}
section.map-section div.search div.search-input-container button.close {
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid var(--color-cta-primary);
  border-left: 0px;
  padding: 10px 2px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 2rem;
  width: 2rem;
  transition: all 0.2s;
  background: url(/wp-content/themes/szero/assets/images/closing-x.svg);
  background-color: #bbbbbb;
  background-position: center center;
  background-size: 60%;
  background-repeat: no-repeat;
}
section.map-section div.search div.search-input-container button.close.hidden {
  display: none;
}
section.map-section div.search div.search-input-container button.close:hover {
  background-color: var(--color-cta-primary);
}
section.map-section div.search .search-results {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  max-height: 600px;
  overflow-y: scroll;
  gap: 5px;
  z-index: 99999;
  top: 2.2rem;
  width: 300px;
  max-width: 100%;
  padding: 20px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.4666666667);
  backdrop-filter: blur(5px);
}
section.map-section div.search .search-results.hidden {
  display: none;
}
section.map-section div.search .search-results button {
  flex: 1;
  max-width: 50%;
  border: 1px solid var(--color-cta-primary);
  text-wrap: nowrap;
  white-space: nowrap;
  border-radius: 6px;
  background: #ffffff;
  padding: 7px 10px;
  transition: all 0.2s;
}
section.map-section div.search .search-results button:hover {
  background-color: var(--color-cta-primary);
}
section.map-section div.legend {
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 9999;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.4666666667);
  backdrop-filter: blur(5px);
  border-radius: 6px;
}
section.map-section div.legend .legend-heart {
  background: -7px -11px/20% no-repeat url(/wp-content/themes/szero/assets/images/map-heart.png);
  padding: 2px 0px 2px 20px;
}
section.map-section div.legend .legend-pin {
  background: -7px -10px/20% no-repeat url(/wp-content/themes/szero/assets/images/map-pin.png);
  padding: 2px 0px 2px 20px;
}
section.map-section div.legend .legend-bg {
  background: -8px -10px/20% no-repeat url(/wp-content/themes/szero/assets/images/map-bg.png);
  padding: 2px 0px 2px 20px;
}
section.map-section div.map {
  flex: 2;
  width: 100%;
  height: 600px;
}
section.map-section div.map .gm-style-iw-d {
  line-height: 150%;
}
section.map-section div.map .gm-style-iw-d h2 {
  margin-bottom: 5px;
}
section.map-section div.text {
  flex: 1;
}
section.map-section div.text p {
  padding-bottom: 10px;
}
section.map-section div.text p:first-child {
  font-size: 1.4em;
  font-weight: 600;
  padding-bottom: 20px;
  color: #191919;
}
section.map-section div.text a {
  color: var(--color-cta-primary);
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  font-weight: 700;
}
section.map-section div.text a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
section.map-section div.text a:active {
  opacity: 1;
  color: var(--color-cta-primary-active);
}

@media (max-width: 600px) {
  section.map-section {
    flex-direction: column;
  }
  section.map-section div.map {
    flex: 2 600px;
  }
  section.map-section div.search div.search-input-container {
    width: 100%;
  }
}
.articles-page-heading {
  margin-bottom: 40px;
  margin-top: 40px;
}

h2.article-section-head {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.article-section-inner {
  display: flex;
  gap: 50px 40px;
  flex-wrap: wrap;
  margin-bottom: 70px;
}
.article-section-inner .article-item {
  flex: 1 0 26%;
  max-width: calc(33.33% - 26.66px);
  transition: opacity 0.5s;
}
.article-section-inner .article-item.fade-in {
  opacity: 0;
}
.article-section-inner .article-item .article-item-date {
  font-size: 0.813rem;
  color: var(--color-cta-primary);
  margin-top: 10px;
}
.article-section-inner .article-item h3 {
  font-family: var(--font-paragraph);
  margin: 15px 0 20px;
  font-size: 1.25rem;
  line-height: 130%;
}
.article-section-inner .article-item .article-item-image {
  height: 194px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
  transition: opacity 0.2s;
  cursor: pointer;
}
.article-section-inner .article-item .article-item-image:hover {
  opacity: 0.8;
}
.article-section-inner .article-item .article-item-ingress {
  font-size: 1rem;
  font-family: var(--font-paragraph);
  overflow: hidden;
}
.article-section-inner .article-item img {
  max-width: 100%;
}
.article-section-inner .article-item a {
  display: inline-block;
  color: var(--color-cta-primary);
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  margin-top: 20px;
  text-underline-offset: 6px;
  text-decoration-thickness: 3px;
  user-select: none;
}

.load-more-container {
  text-align: center;
  color: var(--color-cta-primary);
  font-size: 1.25rem;
  font-weight: 700;
  position: relative;
  height: 22px;
}
.load-more-container a {
  color: var(--color-cta-primary);
  text-underline-offset: 6px;
  text-decoration-thickness: 3px;
  user-select: none;
}

.link-to-all-articles {
  color: var(--color-cta-primary);
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 1.125rem;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}
.link-to-all-articles:hover {
  opacity: 0.6;
  transition: all 0.2s;
}

@media (max-width: 800px) {
  .article-section-inner {
    flex-direction: column;
  }
  .article-section-inner .article-item {
    max-width: 100%;
  }
  .article-section-inner .article-item .article-item-image {
    height: 172px;
  }
}
@media (max-width: 600px) {
  .article-section-inner {
    margin-bottom: 50px;
  }
}
.quote-wrapper {
  width: 60%;
  margin: 0 auto 90px auto;
}
.quote-wrapper p {
  font-size: 1rem;
  line-height: 140%;
}
.quote-wrapper p.quote {
  font-style: italic;
  padding-left: 50px;
  background: var(--quote-icon-url) left top no-repeat;
}

@media (max-width: 600px) {
  .quote-wrapper {
    width: 100%;
    margin-bottom: 70px;
  }
}
.illustration {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.logged-in .illustration {
  margin-bottom: 60px;
}

.sidebar-text {
  margin-bottom: 20px;
  font-size: 1rem;
}

.sidebar-image {
  width: 100%;
  margin-bottom: 20px;
}

.sidebar-heading h2 {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 25px;
  color: var(--color-heading-tertiary);
}

.article-wrapper img {
  width: 100%;
  margin-bottom: 20px;
}
.article-wrapper h2 {
  font-family: var(--font-paragraph);
  color: var(--color-heading-primary);
  font-size: 1rem;
  line-height: 130%;
  margin-bottom: 5px;
}
.article-wrapper a {
  color: var(--color-cta-primary);
  font-weight: 600;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}
.article-wrapper a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
.article-wrapper a:active {
  opacity: 1;
  color: var(--color-cta-primary-active);
}
.article-wrapper .article-text {
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 400;
}
.article-wrapper .article-text a {
  padding-left: 6px;
}

.sidebar-divider {
  background-image: var(--divider-url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 22px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 40px;
}

.sz-important-talks-in-school .sidebar-text,
.sz-parent-support .sidebar-text {
  font-weight: 400;
  font-family: var(--font-paragraph);
}

.top-image-section {
  position: relative;
  margin-top: -30px;
}
.top-image-section img {
  width: 100%;
}
.top-image-section .top-image-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.top-image-section .top-image-background-mobile {
  display: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.top-image-section .top-image-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  min-height: 360px;
}
.top-image-section .top-image-text {
  position: relative;
  text-align: center;
  overflow: hidden;
  max-width: 60%;
  padding: 80px 0 110px 0px;
}
.top-image-section .top-image-text h1 {
  font-size: 3.25rem;
  line-height: 120%;
  font-family: var(--font-head-primary);
  color: var(--color-heading-primary);
  font-weight: 500;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.3333333333);
  max-height: 190px;
  overflow: hidden;
}
.top-image-section .top-image-text p {
  font-size: 1.25rem;
  margin-top: 20px;
  padding: 5px;
  font-weight: 400;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.3333333333);
  line-height: 140%;
  max-height: 65px;
  overflow: hidden;
}
.top-image-section .top-image-link {
  position: absolute;
  bottom: 25px;
  right: 40px;
}
.top-image-section .top-image-link a {
  background: var(--color-cta-secondary) var(--arrow-right-url) no-repeat right 20px center;
  padding: 0 60px 0 20px;
  height: 58px;
  line-height: 57px;
  border-radius: 29px;
  display: inline-block;
  font-family: var(--font-head-primary);
  color: var(--color-bg-primary);
  text-decoration: none;
  font-style: italic;
  font-size: 1.5rem;
  font-weight: 400;
  max-width: 500px;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .top-image-section .top-image-background {
    display: none;
  }
  .top-image-section .top-image-background-mobile {
    display: block;
  }
  .top-image-section .top-image-content {
    flex-direction: column;
    background-size: cover;
    background-position: center;
  }
  .top-image-section .top-image-content .top-image-text {
    position: relative;
    padding: 60px 0;
    max-width: 80%;
  }
  .top-image-section .top-image-content .top-image-text h1 {
    font-size: 2.5rem;
    max-height: 145px;
  }
  .top-image-section .top-image-content .top-image-link {
    position: relative;
    bottom: 0px;
    right: 0px;
    margin-bottom: 25px;
    max-width: calc(100% - 60px);
  }
  .top-image-section .top-image-content .top-image-link a {
    font-size: 1.125rem;
  }
}
@media (max-width: 600px) {
  .top-image-section .top-image-content .top-image-text h1 {
    font-size: 1.625rem;
    max-height: 95px;
  }
  .top-image-section .top-image-content .top-image-text p {
    font-size: 1rem;
    max-height: 50px;
  }
}
.top-image-parent-support-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: -30px;
}
.top-image-parent-support-section .top-image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  aspect-ratio: 3.8/1;
}
.top-image-parent-support-section .image-and-quote-wrapper {
  display: flex;
  position: absolute;
  width: 50%;
}
.top-image-parent-support-section .image-and-quote-wrapper .image-family {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  aspect-ratio: 1.5/1;
}
.top-image-parent-support-section .image-and-quote-wrapper .image-quote {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  aspect-ratio: 1.5/1;
}
.top-image-parent-support-section .top-image-link-parent-support {
  position: absolute;
  bottom: 25px;
  right: 40px;
}
.top-image-parent-support-section .top-image-link-parent-support a {
  background: var(--color-cta-secondary) var(--arrow-right-url) no-repeat right 20px center;
  padding: 0 60px 0 20px;
  height: 58px;
  line-height: 3.56rem;
  border-radius: 29px;
  display: inline-block;
  font-family: var(--font-head-primary);
  color: var(--color-bg-primary);
  text-decoration: none;
  font-style: italic;
  font-size: 1.5rem;
  font-weight: 400;
  max-width: 500px;
  overflow: hidden;
}

@media (max-width: 800px) {
  .top-image-parent-support-section {
    position: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    flex-direction: column;
  }
  .top-image-parent-support-section .top-image {
    display: none;
  }
  .top-image-parent-support-section .image-and-quote-wrapper {
    display: flex;
    position: initial;
    flex-direction: column;
    width: 100%;
  }
  .top-image-parent-support-section .image-and-quote-wrapper .image-family {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    aspect-ratio: 2/1;
  }
  .top-image-parent-support-section .image-and-quote-wrapper .image-quote {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    aspect-ratio: 2/1;
    margin-top: 20px;
  }
  .top-image-parent-support-section .top-image-link-parent-support {
    position: initial;
    margin-top: 20px;
  }
}
.top-image-sz-section {
  position: relative;
  display: flex;
  margin-bottom: 50px;
  margin-top: -30px;
}
.top-image-sz-section .top-image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  aspect-ratio: 2.85/1;
  min-height: 300px;
}
.top-image-sz-section h1 {
  font-family: var(--font-head-secondary);
  color: var(--color-heading-secondary);
  font-size: 4.5rem;
  line-height: 90%;
}
.top-image-sz-section h1.mobile-heading {
  display: none;
  font-size: 2.625rem;
  position: absolute;
  bottom: 20px;
  padding: 0 25px;
}
.top-image-sz-section .content-wrapper {
  display: flex;
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: 90%;
  max-width: 600px;
  flex-direction: column;
}
.top-image-sz-section .content-wrapper p {
  color: var(--color-heading-secondary);
  font-size: 1.125rem;
  line-height: 133%;
  margin-top: 15px;
}
.top-image-sz-section .top-image-link-parent-support a {
  background: var(--color-cta-primary) var(--arrow-right-url) no-repeat right 20px center;
  padding: 0 60px 0 20px;
  height: 40px;
  line-height: 39px;
  border-radius: 20px;
  display: inline-block;
  font-family: var(--font-paragraph);
  color: var(--color-bg-primary);
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 20px;
  max-width: 500px;
  overflow: hidden;
}
.top-image-sz-section.top-image-sz-section-center {
  justify-content: center;
}
.top-image-sz-section.top-image-sz-section-center h1.mobile-heading {
  text-align: center;
}
.top-image-sz-section.top-image-sz-section-center .content-wrapper {
  left: auto;
  text-align: center;
}
.top-image-sz-section.top-image-sz-section-right {
  justify-content: flex-end;
}
.top-image-sz-section.top-image-sz-section-right h1.mobile-heading {
  text-align: right;
}
.top-image-sz-section.top-image-sz-section-right .content-wrapper {
  left: auto;
  text-align: right;
  right: 50px;
}
.top-image-sz-section.low {
  max-height: 300px;
}

@media (max-width: 1000px) {
  .top-image-sz-section h1 {
    font-size: 2.625rem;
  }
}
@media (max-width: 600px) {
  .top-image-sz-section {
    display: block;
  }
  .top-image-sz-section .top-image {
    position: relative;
  }
  .top-image-sz-section h1.mobile-heading {
    display: block;
  }
  .top-image-sz-section .top-image-link-parent-support {
    text-align: center;
  }
  .top-image-sz-section .content-wrapper {
    position: relative;
    bottom: auto;
    width: 100%;
    padding: 25px;
    left: 0px;
  }
  .top-image-sz-section .content-wrapper h1 {
    display: none;
  }
  .top-image-sz-section .content-wrapper p {
    color: var(--color-heading-tertiary);
    margin-top: 0;
  }
  .top-image-sz-section.top-image-sz-section-right .content-wrapper {
    right: 0px;
  }
  .top-image-sz-section.low {
    max-height: none;
  }
}
.sz-important-talks-in-school .top-image-sz-section h1 {
  font-size: 2.25rem;
  line-height: 165%;
  text-transform: uppercase;
}
.sz-important-talks-in-school .top-image-sz-section .content-wrapper {
  bottom: 0;
  height: 100%;
  justify-content: center;
}

.link-list-wrapper {
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
}
.link-list-wrapper h2 {
  font-family: var(--font-head-secondary);
  color: var(--color-heading-primary);
  text-transform: uppercase;
  font-size: 1.313rem;
  line-height: 2.166rem;
  margin-bottom: 11px;
}
.link-list-wrapper p {
  margin-bottom: 20px;
  font-weight: 400;
}
.link-list-wrapper ul {
  margin: 0.5rem 0;
}
.link-list-wrapper li {
  padding: 20px;
  list-style-type: none;
}
.link-list-wrapper li a {
  text-decoration: none;
  color: var(--color-fg-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link-list-wrapper li .link-text-wrapper {
  max-width: 85%;
}
.link-list-wrapper li .link-text-wrapper p {
  font-size: 1rem;
  line-height: 1.4rem;
  margin-bottom: 0px;
}
.link-list-wrapper li .link-text-wrapper .heading {
  font-weight: 600;
  margin-bottom: 0px;
}
.link-list-wrapper li .external-link-icon {
  background-image: var(--link-external);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 24px;
  width: 28px;
}
.link-list-wrapper li:nth-child(odd) {
  background: #dddddd;
}
.link-list-wrapper li:nth-child(odd).add-hover:hover {
  opacity: 0.7;
}
.link-list-wrapper li:nth-child(even) {
  background: #eeeeee;
}
.link-list-wrapper li:nth-child(even).add-hover:hover {
  opacity: 0.7;
}

body.sz-important-talks-in-school .link-list-wrapper li:nth-child(odd),
body.sz-important-talks-in-school .link-list-wrapper li:nth-child(even) {
  background: var(--color-bg-secondary);
  border-bottom: 1px solid var(--color-cta-primary);
  padding: 10px 20px;
  transition: 0.2s all;
}
body.sz-important-talks-in-school .link-list-wrapper li:nth-child(odd).add-hover:hover,
body.sz-important-talks-in-school .link-list-wrapper li:nth-child(even).add-hover:hover {
  background: var(--color-cta-listing);
  opacity: 1;
}
body.sz-important-talks-in-school .link-list-wrapper li:last-child {
  border-bottom: none !important;
}
body.sz-important-talks-in-school .link-list-wrapper .link-list-head {
  margin-bottom: 30px;
}
body.sz-important-talks-in-school .link-list-wrapper .link-list-head p {
  margin-bottom: 0;
}

body.sz-main .link-list-wrapper h2 {
  text-transform: none;
  font-family: var(--font-head-primary);
  color: var(--color-heading-tertiary);
  font-size: 1.625rem;
}

@media (max-width: 600px) {
  body.sz-main .link-list-wrapper h2 {
    font-size: 1.5rem;
  }
}
.form-section.order-form-school .form-content-box.shopping-cart {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-information {
  min-width: 70%;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-information .price {
  margin-bottom: 0;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-information .price .price-per-box {
  font-weight: 700;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-amount {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  min-width: 30%;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-amount p {
  margin-bottom: 0;
  white-space: nowrap;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-amount .shopping-cart-buttons {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-amount .shopping-cart-buttons #number-of-boxes {
  height: 28px;
  width: 28px;
  margin-top: 0;
  padding: 0;
  text-align: center;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-amount .shopping-cart-buttons .shopping-cart-button {
  height: 28px;
  width: 28px;
  background-color: #DCE2FA;
  border: none;
  color: #234D96;
  border-radius: 4.45px;
  cursor: pointer;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-amount .shopping-cart-buttons .shopping-cart-button.decrease {
  background-image: var(--minus-icon);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-amount .shopping-cart-buttons .shopping-cart-button.increase {
  background-image: var(--plus-icon);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-amount #total-price {
  font-weight: 700;
}
.form-section.order-form-school .form-content-box {
  background: var(--color-bg-secondary);
  font-weight: 400;
  padding: 50px;
}
.form-section.order-form-school .form-content-box h4 {
  font-weight: 500;
}
.form-section.order-form-school .form-content-box p {
  margin-bottom: 37px;
}
.form-section.order-form-school .form-content-box .form-checkbox a {
  color: var(--color-fg-primary);
  font-weight: 700;
}
.form-section.order-form-school .form-content-box .radiobutton-container {
  font-size: 1rem;
}
.form-section.order-form-school .form-content-box #invoice-email-label,
.form-section.order-form-school .form-content-box #email-label {
  flex-basis: 50%;
  margin-bottom: 0px;
}
.form-section.order-form-school .form-content-box #invoice-email-label #invoice-email,
.form-section.order-form-school .form-content-box #invoice-email-label #email,
.form-section.order-form-school .form-content-box #email-label #invoice-email,
.form-section.order-form-school .form-content-box #email-label #email {
  width: calc(100% - 20px);
}
.form-section.order-form-school .form-content-box #e-invoice-wrapper {
  display: none;
}
.form-section.order-form-school .form-content-box #e-invoice-wrapper .reference-code-description {
  font-size: 0.813rem;
  font-style: italic;
}
.form-section.order-form-school .submit-button-container #submit-button-order-form {
  background: var(--color-cta-primary);
  color: var(--color-fg-quaternary);
  height: 40px;
  border-radius: 20px;
  border: none;
  line-height: 100%;
  font-size: 1.063rem;
  padding: 10px 16px;
  font-weight: bold;
  margin: 30px 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.form-section.order-form-school .submit-button-container #submit-button-order-form:hover {
  opacity: 0.6;
}
.form-section.order-form-school .submit-button-container #submit-button-order-form:focus {
  outline: 4px solid #444;
}

@media (max-width: 600px) {
  .form-section.order-form-school .form-content-box.shopping-cart {
    flex-direction: column;
  }
  .form-section.order-form-school .form-content-box.shopping-cart .shopping-cart-information {
    max-width: 100%;
  }
  .form-section.order-form-school .form-content-box {
    padding: 23px;
  }
  .form-section.order-form-school .form-content-box .form-content-fields #invoice-email-label,
  .form-section.order-form-school .form-content-box .form-content-fields #email-label {
    flex-basis: inherit;
    margin-bottom: 0px;
  }
  .form-section.order-form-school .form-content-box .form-content-fields #invoice-email-label #invoice-email,
  .form-section.order-form-school .form-content-box .form-content-fields #invoice-email-label #email,
  .form-section.order-form-school .form-content-box .form-content-fields #email-label #invoice-email,
  .form-section.order-form-school .form-content-box .form-content-fields #email-label #email {
    width: 100%;
  }
}
section.text_section {
  line-height: 140%;
}
section.text_section h1,
section.text_section h2,
section.text_section h3,
section.text_section h4,
section.text_section h5 {
  line-height: 140%;
  font-family: var(--font-head-primary);
  font-weight: 500;
  margin-bottom: 10px;
}
section.text_section h1 {
  font-size: 4.5rem;
}
section.text_section h2 {
  font-size: 3.5rem;
}
section.text_section h3 {
  font-size: 1.625rem;
}
section.text_section h4 {
  font-size: 1.25rem;
}
section.text_section h5 {
  font-size: 1.125rem;
}
section.text_section p {
  margin-bottom: 7px;
  font-size: 1rem;
  font-weight: 400;
  width: fit-content;
  line-height: 140%;
}
section.text_section a {
  color: var(--color-cta-primary);
  text-decoration: none;
}
section.text_section ul li, section.text_section ol li {
  font-family: var(--font-paragraph);
  font-weight: 400;
}

section.introduction_section a {
  border-bottom: 3px solid var(--color-cta-primary);
  font-weight: 700;
  color: var(--color-cta-primary);
  text-decoration: none;
}
section.introduction_section a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
section.introduction_section a:active {
  opacity: 1;
  color: var(--color-cta-primary-active) !important;
  border-bottom: 3px solid var(--color-cta-primary-active) !important;
}

body.sz-main section.text_section h1,
body.sz-main section.text_section h2 {
  font-family: var(--font-head-secondary);
}
body.sz-main section.text_section h1,
body.sz-main section.text_section h2,
body.sz-main section.text_section h3,
body.sz-main section.text_section h4,
body.sz-main section.text_section h5 {
  font-weight: 700;
  margin-bottom: 10px;
}
body.sz-main section.text_section p {
  margin-bottom: 7px;
}
body.sz-main section.text_section a {
  border-bottom: 3px solid var(--color-cta-primary);
  font-weight: 700;
}
body.sz-main section.text_section a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
body.sz-main section.text_section a:active {
  opacity: 1;
  color: var(--color-cta-primary-active);
  border-bottom: 3px solid var(--color-cta-primary-active);
}
body.sz-main section.introduction_section {
  font-weight: 700;
}
body.sz-main section.introduction_section h1,
body.sz-main section.introduction_section h2,
body.sz-main section.introduction_section h3,
body.sz-main section.introduction_section h4,
body.sz-main section.introduction_section h5 {
  margin-bottom: 10px;
}
body.sz-main section.introduction_section p {
  margin-bottom: 7px;
}

body.sz-important-talks-in-school section.text_section a:hover,
body.sz-parent-support section.text_section a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
body.sz-important-talks-in-school section.text_section a:active,
body.sz-parent-support section.text_section a:active {
  opacity: 1;
  color: var(--color-cta-primary-active);
}

body.one-column section.text_section {
  width: 65%;
  margin: 0 auto;
  margin-bottom: 90px;
}

body.two-column section.text_section {
  padding-right: 30%;
  margin-bottom: 90px;
}

section.introduction_section {
  font-size: 1.12rem;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 140%;
}

body.one-column section.introduction_section {
  width: 65%;
  margin: 0 auto;
  margin-bottom: 30px;
}

body.two-column section.introduction_section {
  padding-right: 30%;
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  body.one-column section.text_section,
  body.one-column section.introduction_section {
    width: 100%;
    margin-bottom: 70px;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h4 {
    font-size: 0.9rem;
  }
  body.one-column section.text_section,
  body.two-column section.text_section,
  body.three-column section.text_section {
    font-size: 0.9rem;
    padding-right: 0;
  }
  body.one-column section.introduction_section,
  body.two-column section.introduction_section,
  body.three-column section.introduction_section {
    font-size: 1rem;
    padding-right: 0;
  }
}
.two-columns-with-text-and-associated-image-section {
  display: flex;
  flex: 1;
  gap: 40px;
  align-items: center;
}
.two-columns-with-text-and-associated-image-section .two-column-item {
  flex: 1;
  width: 100%;
}
.two-columns-with-text-and-associated-image-section .two-column-item.two-column-item-left {
  order: 1;
}
.two-columns-with-text-and-associated-image-section .two-column-item.two-column-item-right {
  order: 2;
}
.two-columns-with-text-and-associated-image-section .two-column-item h3 {
  margin-bottom: 15px;
}
.two-columns-with-text-and-associated-image-section .two-column-item h3.lsis {
  font-family: var(--font-head-secondary);
  color: var(--color-heading-primary);
  line-height: 2.125rem;
  font-size: 1.313rem;
  text-transform: uppercase;
}
.two-columns-with-text-and-associated-image-section .two-column-item h3.h3-regular {
  line-height: 2.5rem;
  font-size: 1.875rem;
  font-weight: 500;
  color: var(--color-heading-tertiary);
  line-height: 125%;
}
.two-columns-with-text-and-associated-image-section .two-column-item .two-column-text p {
  margin-bottom: 15px;
  font-weight: 400;
}
.two-columns-with-text-and-associated-image-section .two-column-item .two-column-text a {
  color: var(--color-cta-primary);
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  font-weight: 700;
}
.two-columns-with-text-and-associated-image-section .two-column-item .two-column-text a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
.two-columns-with-text-and-associated-image-section .two-column-item .two-column-text a:active {
  opacity: 1;
  color: var(--color-cta-primary-active);
}
.two-columns-with-text-and-associated-image-section .two-column-item .two-column-text ul, .two-columns-with-text-and-associated-image-section .two-column-item .two-column-text li {
  margin-bottom: 10px;
}
.two-columns-with-text-and-associated-image-section .two-column-item .image {
  width: 100%;
  aspect-ratio: 1.6/1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 12px;
}
.two-columns-with-text-and-associated-image-section .two-column-item .two-column-link {
  display: flex;
  justify-content: center;
}
.two-columns-with-text-and-associated-image-section .two-column-item .two-column-link a {
  margin-top: 10px;
  background: var(--color-cta-primary);
  height: 46px;
  line-height: 45px;
  border-radius: 23px;
  display: inline-block;
  padding: 0 30px;
  text-decoration: none;
  color: var(--color-bg-primary);
  font-family: var(--font-head-primary);
  font-weight: 400;
  font-style: italic;
  font-size: 1.25rem;
}

body.sz-main .two-columns-with-text-and-associated-image-section .two-column-item h3 {
  font-weight: 700;
}
body.sz-main .two-columns-with-text-and-associated-image-section .two-column-item .two-column-link {
  justify-content: start;
}
body.sz-main .two-columns-with-text-and-associated-image-section .two-column-item .two-column-link a {
  background: none;
  color: var(--color-cta-primary);
  font-family: var(--font-paragraph);
  font-style: normal;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  border-radius: 0px;
  padding: 0;
  font-size: 1.125rem;
  line-height: 160%;
  height: auto;
  font-weight: 700;
}
body.sz-main .two-columns-with-text-and-associated-image-section .two-column-item .two-column-link a:active {
  opacity: 1;
  color: var(--color-cta-primary-active) !important;
  background-color: transparent !important;
}

@media (max-width: 1000px) {
  .two-columns-with-text-and-associated-image-section {
    flex-direction: column;
    gap: 25px;
  }
  .two-columns-with-text-and-associated-image-section .two-column-item.two-column-image {
    order: 1 !important;
  }
  .two-columns-with-text-and-associated-image-section .two-column-item.two-column-text {
    order: 2 !important;
  }
}
@media (max-width: 600px) {
  .sz-main .two-columns-with-text-and-associated-image-section .two-column-item h3 {
    font-size: 1.625rem;
  }
}
.video-section {
  display: flex;
  gap: 40px;
  align-items: center;
}
.video-section > * {
  flex: 1;
}
.video-section h3 {
  margin-bottom: 15px;
  line-height: 125%;
  font-size: 1.875rem;
  font-weight: 500;
  color: var(--color-heading-tertiary);
}
.video-section p {
  margin-bottom: 15px;
  font-weight: 400;
}
.video-section a {
  color: var(--color-cta-primary);
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  font-weight: 700;
}
.video-section a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
.video-section a:active {
  opacity: 1;
  color: var(--color-cta-primary-active);
}
.video-section iframe {
  width: 100%;
  border-radius: 12px;
}
@media (max-width: 600px) {
  .video-section {
    flex-direction: column;
    gap: 25px;
  }
  .video-section .text-column {
    order: 1 !important;
  }
  .video-section iframe {
    border-radius: 0px;
  }
  .video-section h3 {
    font-size: 1.625rem;
  }
}
.video-section.column-order-left .text-column {
  order: 2;
}

.sz-main .video-section h3 {
  font-weight: 700;
}

.wiggly-box-canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.wiggly-menu-container {
  width: 100%;
  position: relative;
  padding: 5px;
}
.wiggly-menu-container > ul {
  padding: min(30px, 3vw);
  padding-bottom: 0px;
  display: block;
  z-index: 1;
  background: none;
  position: relative;
  overflow: hidden;
}
.wiggly-menu-container > ul > li {
  list-style-type: none;
  font-size: 1.063rem;
  font-weight: 500;
  margin-bottom: 30px;
}
.wiggly-menu-container > ul > li.parent {
  font-size: 1.313rem;
  font-weight: 400;
  text-transform: none;
}
.wiggly-menu-container > ul > li.current_page_parent {
  font-weight: 700;
}
.wiggly-menu-container > ul > li.current_page_item {
  font-weight: 700;
}
.wiggly-menu-container > ul > li.parent-page-active {
  font-weight: 700;
}
.wiggly-menu-container > ul > li ul {
  padding: 20px 0px 0px 20px;
}
.wiggly-menu-container > ul > li ul li {
  text-transform: none;
  list-style-type: none;
  font-size: 1rem;
  font-weight: 400;
  padding: 0 0 1.25rem 0;
}
.wiggly-menu-container > ul > li ul li.current_page_item {
  font-weight: 700;
}
.wiggly-menu-container > ul > li ul li:last-child {
  padding: 0;
}
.wiggly-menu-container > ul > li a {
  text-decoration: none;
  color: var(--color-fg-secondary);
}
.wiggly-menu-container > ul > li a:hover {
  opacity: 0.6;
}
.wiggly-menu-container > ul > li a:active {
  opacity: 1;
  color: var(--color-cta-primary-active);
}

.wiggly-line-canvas {
  width: 100%;
}

@media (max-width: 1000px) {
  .wiggly-menu-container {
    display: none;
  }
}
.yellow-dot-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.yellow-dot-section .yellow-dot-container {
  position: relative;
  text-align: center;
}
.yellow-dot-section .yellow-dot-container .image {
  background-image: var(--yellow-dot);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 160px;
  width: 170px;
}
.yellow-dot-section .yellow-dot-container .image:hover {
  cursor: pointer;
}
.yellow-dot-section .yellow-dot-container .image .text {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: black;
  text-decoration: underline;
  text-underline-offset: 3px;
  font-weight: 700;
  line-height: 140%;
  font-family: var(--font-paragraph);
  width: 80%;
}

/*
 * Forms
 *
 */
.form-section {
  max-width: 700px;
  margin: 0 auto 90px;
  /* Removing arrows on numbers fields - Most browsers*/
  /* Removing arrows on numbers fields - Firefox */
  /* Customize the label (the container) */
}
.form-section .input-error {
  color: red !important;
  display: none;
  font-size: 0.875rem;
}
.form-section .form-content-box {
  background: var(--color-bg-tertiary);
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 6px;
}
.form-section .form-content-box h4 {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 30px;
}
.form-section .form-content-box #social-security-number-label {
  width: calc(50% - 20px);
  margin-bottom: 20px;
}
.form-section .form-content-box input,
.form-section .form-content-box select {
  margin-top: 8px;
}
.form-section .form-content-box .form-content-fields {
  display: flex;
  gap: 20px 40px;
  flex-wrap: wrap;
}
.form-section .form-content-box .form-content-fields label {
  width: calc(50% - 20px);
}
.form-section .form-content-box .form-content-fields.form-content-fields-single-middle {
  justify-content: space-around;
}
.form-section .form-content-box .privacy-policy {
  background: var(--color-bg-secondary);
  padding: 20px 30px;
  margin-top: 30px;
}
.form-section .form-content-box .privacy-policy.swish {
  margin-top: 60px;
}
.form-section .form-content-box .privacy-policy a {
  color: var(--color-fg-primary);
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
  font-size: 1rem;
  font-weight: 700;
  width: fit-content;
}
.form-section .form-content-box .privacy-policy a:hover {
  opacity: 0.6;
  transition: all 0.2s;
}
.form-section .form-content-box .privacy-policy a:focus {
  outline: 4px solid #444;
}
.form-section .form-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 80%;
  margin-left: 10%;
  margin: 50px 0 50px 10%;
}
.form-section .form-divider.form-divider-simple {
  margin: 20px 0 15px 10%;
}
.form-section .form-divider.form-divider-monthly {
  display: none;
}
.form-section .form-divider.form-divider-monthly.show-divider {
  display: block;
}
.form-section .form-divider span.line {
  background: var(--color-bg-secondary);
  height: 1px;
  display: block;
  width: 100%;
}
.form-section .form-divider span.text {
  background: var(--color-bg-tertiary);
  color: var(--color-fg-primary);
  position: absolute;
  padding: 0 15px;
}
.form-section input::-webkit-outer-spin-button,
.form-section input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-section input[type=number] {
  -moz-appearance: textfield;
}
.form-section .donate-amount {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 25px;
  margin: 30px 0;
}
.form-section .donate-amount li {
  flex: 1;
  height: 46px;
  position: relative;
  text-align: center;
}
.form-section .donate-amount label,
.form-section .donate-amount input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  font-size: 1rem;
}
.form-section .donate-amount label:focus + .label-for-amount,
.form-section .donate-amount input:focus + .label-for-amount {
  outline: 4px solid #444;
}
.form-section .donate-amount input[type=radio] {
  opacity: 0.01;
  z-index: 100;
}
.form-section .donate-amount input[type=radio]:checked + label,
.form-section .donate-amount .Checked + label {
  background: var(--color-cta-primary);
  color: var(--color-heading-secondary);
}
.form-section .donate-amount label {
  line-height: 43px;
  z-index: 1;
  background: #fff;
  border: 2px solid var(--color-cta-primary);
  border-radius: 6px;
  font-weight: 700;
  display: block;
}
.form-section .form-checkbox {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 30px;
  margin-top: 30px;
  cursor: pointer;
  font-size: 1.063rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 32px;
}
.form-section .form-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-section .form-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.form-section .form-checkbox input:focus + .checkmark {
  outline: 4px solid #444;
}
.form-section .form-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border: 1px solid var(--color-cta-primary);
  border-radius: 6px;
}
.form-section .form-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 4px;
  width: 22px;
  height: 22px;
  background: var(--color-cta-primary);
  border-radius: 5px;
}
.form-section .monthly-donation-fields {
  display: none;
}
.form-section .monthly-donation-fields.monthly-donation-fields-display {
  display: block;
}
.form-section .private-donation-fields {
  display: block;
}
.form-section .private-donation-fields.private-donation-fields-hide {
  display: none;
}
.form-section input[type=number],
.form-section input[type=text],
.form-section input[type=date],
.form-section input[type=password],
.form-section select {
  width: 100%;
  height: 46px;
  border: 1px solid var(--color-cta-primary);
  border-radius: 6px;
  padding: 0 18px;
  font-size: 0.875rem;
  font-family: var(--font-paragraph);
}
.form-section input[type=number]:focus,
.form-section input[type=text]:focus,
.form-section input[type=date]:focus,
.form-section input[type=password]:focus,
.form-section select:focus {
  border: 4px solid #444;
  outline: none;
}
.form-section input:not(:focus):not(:placeholder-shown):invalid {
  border: 2px solid red !important;
  border-radius: 5px;
}
.form-section input:not(:focus):not(:placeholder-shown):invalid + .input-error {
  display: block;
}
.form-section .input-error {
  color: red !important;
  display: none;
  font-size: 0.875rem;
}
.form-section .own-amount-container label {
  font-size: 1.125rem;
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
.form-section .other-form-link {
  text-align: center;
  margin-top: 20px;
}
.form-section .other-form-link a {
  display: inline-block;
  color: var(--color-cta-secondary);
  font-weight: 700;
  font-size: 1rem;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
  transition: 0.3s all;
}
.form-section .other-form-link a:hover {
  opacity: 0.6;
}
.form-section .radiobutton-container {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.125rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 28px;
}
.form-section .radiobutton-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-section .radiobutton-container input:checked ~ .checkmark:after {
  display: block;
}
.form-section .radiobutton-container input:focus + .checkmark {
  outline: 4px solid #444;
}
.form-section .radiobutton-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: var(--color-bg-primary);
  border-radius: 50%;
  border: 1px solid var(--color-cta-primary);
}
.form-section .radiobutton-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--color-cta-primary-active);
}
.form-section .radiobutton-container input:checked ~ .checkmark {
  border: 2px solid var(--color-cta-primary-active);
}
.form-section .radiobutton-container.payment-container-hide {
  display: none;
}
.form-section .submit-button-container {
  text-align: center;
}
.form-section .submit-button-container #submit-button,
.form-section .submit-button-container .submit-button {
  background: var(--color-cta-primary);
  color: var(--color-heading-secondary);
  height: 40px;
  border-radius: 20px;
  border: none;
  line-height: 100%;
  font-size: 1.063rem;
  padding: 10px 16px;
  font-weight: bold;
  margin: 30px 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.form-section .submit-button-container #submit-button:hover,
.form-section .submit-button-container .submit-button:hover {
  opacity: 0.6;
}
.form-section .submit-button-container #submit-button:focus,
.form-section .submit-button-container .submit-button:focus {
  outline: 4px solid #444;
}

.form-section-member .form-content-box #social-security-number-label,
.form-section-member .form-content-box #city-label {
  flex-basis: 50%;
  margin-bottom: 0px;
}
.form-section-member .form-content-box #social-security-number-label #social-security-number,
.form-section-member .form-content-box #social-security-number-label #city,
.form-section-member .form-content-box #city-label #social-security-number,
.form-section-member .form-content-box #city-label #city {
  width: calc(100% - 20px);
}
.form-section-member #social-security-number-label {
  display: block;
}

.form-section.form-section-monthly .form-content-box #social-security-number-label {
  display: block;
}

#form-content-box-private-account-number {
  display: none;
}
#form-content-box-private-account-number.display {
  display: block;
}

.form-section.form-section-monthly .form-content-box #social-security-number-label,
.form-section.form-section-private .form-content-box #social-security-number-label {
  flex-basis: 50%;
  margin-bottom: 0px;
}
.form-section.form-section-monthly .form-content-box #social-security-number-label #social-security-number,
.form-section.form-section-private .form-content-box #social-security-number-label #social-security-number {
  width: calc(100% - 20px);
}
.form-section.form-section-monthly .form-content-box .brite-filling,
.form-section.form-section-private .form-content-box .brite-filling {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}
.form-section.form-section-monthly .form-content-box .brite-filling .get-personal-information-btn,
.form-section.form-section-private .form-content-box .brite-filling .get-personal-information-btn {
  background: var(--color-cta-primary);
  color: var(--color-heading-secondary);
  height: 40px;
  border-radius: 20px;
  border: none;
  line-height: 100%;
  font-size: 1.063rem;
  padding: 10px 16px;
  font-weight: bold;
  margin: 30px 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.form-section.form-section-monthly .form-content-box .brite-filling .get-personal-information-btn:hover,
.form-section.form-section-private .form-content-box .brite-filling .get-personal-information-btn:hover {
  opacity: 0.6;
}
.form-section.form-section-monthly .form-content-box .brite-filling .get-personal-information-btn:focus,
.form-section.form-section-private .form-content-box .brite-filling .get-personal-information-btn:focus {
  outline: 4px solid #444;
}
.form-section.form-section-monthly .form-content-box .brite-filling #manual-fill-btn,
.form-section.form-section-private .form-content-box .brite-filling #manual-fill-btn {
  overflow: hidden;
  background: none;
  border: none;
  font-size: var(--font-size);
  font-weight: 700;
  color: var(--color-cta-primary);
  margin-bottom: 20px;
  cursor: pointer;
}
.form-section.form-section-monthly .form-content-box .brite-filling #manual-fill-btn:hover,
.form-section.form-section-private .form-content-box .brite-filling #manual-fill-btn:hover {
  opacity: 0.6;
}
.form-section.form-section-monthly .form-content-box .brite-filling #manual-fill-btn:focus,
.form-section.form-section-private .form-content-box .brite-filling #manual-fill-btn:focus {
  opacity: 0.6;
}
.form-section.form-section-monthly .form-content-box #manual-filling-form,
.form-section.form-section-private .form-content-box #manual-filling-form {
  height: 0px;
  overflow: hidden;
  transition: all 0.5s;
}
.form-section.form-section-monthly .form-content-box #manual-filling-form #manual-filling-form-inner,
.form-section.form-section-private .form-content-box #manual-filling-form #manual-filling-form-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 40px;
}

.form-section-memorial-gift .form-content-box #date-label,
.form-section-memorial-gift .form-content-box #in-memory-of-label,
.form-section-memorial-gift .form-content-box #social-security-number-label,
.form-section-memorial-gift .form-content-box #city-label {
  flex-basis: 50%;
  margin-bottom: 0px;
}
.form-section-memorial-gift .form-content-box #date-label #date,
.form-section-memorial-gift .form-content-box #date-label #in-memory-of,
.form-section-memorial-gift .form-content-box #date-label #social-security-number,
.form-section-memorial-gift .form-content-box #date-label #city,
.form-section-memorial-gift .form-content-box #in-memory-of-label #date,
.form-section-memorial-gift .form-content-box #in-memory-of-label #in-memory-of,
.form-section-memorial-gift .form-content-box #in-memory-of-label #social-security-number,
.form-section-memorial-gift .form-content-box #in-memory-of-label #city,
.form-section-memorial-gift .form-content-box #social-security-number-label #date,
.form-section-memorial-gift .form-content-box #social-security-number-label #in-memory-of,
.form-section-memorial-gift .form-content-box #social-security-number-label #social-security-number,
.form-section-memorial-gift .form-content-box #social-security-number-label #city,
.form-section-memorial-gift .form-content-box #city-label #date,
.form-section-memorial-gift .form-content-box #city-label #in-memory-of,
.form-section-memorial-gift .form-content-box #city-label #social-security-number,
.form-section-memorial-gift .form-content-box #city-label #city {
  width: calc(100% - 20px);
}
.form-section-memorial-gift .form-content-box #write-own-greeting-label,
.form-section-memorial-gift .form-content-box #honored-by-label,
.form-section-memorial-gift .form-content-box #receiver-name-label,
.form-section-memorial-gift .form-content-box #receiver-address-label {
  flex-basis: 100%;
  margin-bottom: 0px;
}
.form-section-memorial-gift .form-content-box .pre-chosen-greetings {
  margin-bottom: 20px;
  appearance: none;
  background-image: var(--arrow-down-url);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 0.7rem;
}
.form-section-memorial-gift .form-content-box .pre-chosen-greetings.show,
.form-section-memorial-gift .form-content-box .write-own-greeting.show,
.form-section-memorial-gift .form-content-box .company-payment.show,
.form-section-memorial-gift .form-content-box .private-payment.show {
  display: block;
}
.form-section-memorial-gift .form-content-box .pre-chosen-greetings.hide,
.form-section-memorial-gift .form-content-box .write-own-greeting.hide,
.form-section-memorial-gift .form-content-box .company-payment.hide,
.form-section-memorial-gift .form-content-box .private-payment.hide {
  display: none;
}
.form-section-memorial-gift .form-content-box .preview-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  text-decoration: none;
  background: var(--color-bg-primary);
  border: 2px solid var(--color-cta-primary);
  color: var(--color-cta-primary);
  height: 40px;
  border-radius: 20px;
  font-size: 1.063rem;
  padding: 10px 16px;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.form-section-memorial-gift .form-content-box .preview-btn:hover {
  background: var(--color-cta-primary);
  color: var(--color-bg-primary);
}
.form-section-memorial-gift .form-content-box .preview-btn:focus {
  outline: 4px solid #444;
}
.form-section-memorial-gift .form-content-box #payment-content-fields.show-private-person .private-payment {
  display: block;
}
.form-section-memorial-gift .form-content-box #payment-content-fields.show-private-person .company-payment {
  display: none;
}
.form-section-memorial-gift .form-content-box #payment-content-fields.show-company .company-payment {
  display: block;
}
.form-section-memorial-gift .form-content-box #payment-content-fields.show-company .private-payment {
  display: none;
}
.form-section-memorial-gift .form-content-box #receiver-content-fields {
  margin-top: 20px;
}
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-email .receiver-email-label {
  display: block;
}
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-email .receiver-name-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-email .receiver-address-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-email .receiver-zipcode-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-email .receiver-city-label {
  display: none;
}
.form-section-memorial-gift .form-content-box #receiver-content-fields.hide-all .receiver-email-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.hide-all .receiver-name-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.hide-all .receiver-address-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.hide-all .receiver-zipcode-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.hide-all .receiver-city-label {
  display: none;
}
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-name-and-address .receiver-name-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-name-and-address .receiver-address-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-name-and-address .receiver-zipcode-label,
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-name-and-address .receiver-city-label {
  display: block;
}
.form-section-memorial-gift .form-content-box #receiver-content-fields.show-name-and-address .receiver-email-label {
  display: none;
}

@media (max-width: 600px) {
  .form-section {
    margin: 0 -20px 70px;
  }
  .form-section .form-divider {
    margin: 25px 0 25px 10%;
  }
  .form-section .form-content-box {
    padding: 30px 20px;
  }
  .form-section .donate-amount {
    gap: 8px;
  }
  .form-section .form-divider-simple {
    margin: 25px 0 20px 10%;
  }
  .form-section .form-content-box .form-content-fields label {
    width: 100%;
  }
  .form-content-box #social-security-number-label {
    width: 100% !important;
  }
  .form-section-member .form-content-box #social-security-number-label,
  .form-section-member .form-content-box #city-label {
    flex-basis: 100%;
  }
  .form-section-member .form-content-box #social-security-number-label #social-security-number,
  .form-section-member .form-content-box #social-security-number-label #city,
  .form-section-member .form-content-box #city-label #social-security-number,
  .form-section-member .form-content-box #city-label #city {
    width: 100%;
  }
  .form-section-memorial-gift .form-content-box #date-label,
  .form-section-memorial-gift .form-content-box #in-memory-of-label,
  .form-section-memorial-gift .form-content-box #social-security-number-label,
  .form-section-memorial-gift .form-content-box #city-label {
    flex-basis: 100%;
  }
  .form-section-memorial-gift .form-content-box #date-label #date,
  .form-section-memorial-gift .form-content-box #date-label #in-memory-of,
  .form-section-memorial-gift .form-content-box #date-label #social-security-number,
  .form-section-memorial-gift .form-content-box #date-label #city,
  .form-section-memorial-gift .form-content-box #in-memory-of-label #date,
  .form-section-memorial-gift .form-content-box #in-memory-of-label #in-memory-of,
  .form-section-memorial-gift .form-content-box #in-memory-of-label #social-security-number,
  .form-section-memorial-gift .form-content-box #in-memory-of-label #city,
  .form-section-memorial-gift .form-content-box #social-security-number-label #date,
  .form-section-memorial-gift .form-content-box #social-security-number-label #in-memory-of,
  .form-section-memorial-gift .form-content-box #social-security-number-label #social-security-number,
  .form-section-memorial-gift .form-content-box #social-security-number-label #city,
  .form-section-memorial-gift .form-content-box #city-label #date,
  .form-section-memorial-gift .form-content-box #city-label #in-memory-of,
  .form-section-memorial-gift .form-content-box #city-label #social-security-number,
  .form-section-memorial-gift .form-content-box #city-label #city {
    width: 100%;
  }
}
body.sz-important-talks-in-school .form-section .form-content-box {
  background: var(--color-bg-secondary) !important;
  font-weight: 400;
}
body.sz-important-talks-in-school .form-section .form-content-box h2 {
  font-family: var(--font-paragraph);
}
body.sz-important-talks-in-school .form-section .form-content-box .info-text {
  margin-top: 20px;
}
body.sz-important-talks-in-school .form-section .form-content-box .form-content-fields {
  margin-top: 40px;
}
body.sz-important-talks-in-school .form-section .form-content-box .form-content-fields label {
  font-family: var(--font-paragraph);
  font-weight: 400;
}
body.sz-important-talks-in-school .form-section .form-content-box .submit-button-container .submit-button {
  color: var(--color-bg-primary);
  padding: 10px 30px;
  margin: 40px 0 12px;
}
body.sz-important-talks-in-school .form-section .form-content-box input[type=date]:focus,
body.sz-important-talks-in-school .form-section .form-content-box input[type=number]:focus,
body.sz-important-talks-in-school .form-section .form-content-box input[type=text]:focus,
body.sz-important-talks-in-school .form-section .form-content-box select:focus {
  border: 4px solid var(--color-cta-primary);
  outline: none;
}
body.sz-important-talks-in-school .form-section .form-content-box .errors {
  color: var(--color-cta-secondary);
  text-align: center;
  margin-bottom: 20px;
}
body.sz-important-talks-in-school .form-section .create-account-link {
  width: fit-content;
  display: flex;
  justify-content: center;
  color: var(--color-cta-primary);
  text-decoration: none;
  font-weight: 500;
  margin: 0 auto 32px auto;
}
body.sz-important-talks-in-school .form-section .link-container {
  text-align: center;
  display: block;
}
body.sz-important-talks-in-school .form-section .link-container #forgot-password-link {
  display: inline-block;
  text-align: center;
  font-style: italic;
  font-weight: 400;
  text-decoration: underline 2px;
  text-underline-offset: 3px;
  cursor: pointer;
  transition: all 0.2s;
}
body.sz-important-talks-in-school .form-section .link-container #forgot-password-link:hover {
  opacity: 0.6;
}

#form-content-forgot-password,
#form-content-login {
  display: none;
}
#form-content-forgot-password.display,
#form-content-login.display {
  display: block;
}
#form-content-forgot-password .confirm-message,
#form-content-login .confirm-message {
  display: none;
}
#form-content-forgot-password .confirm-message p,
#form-content-login .confirm-message p {
  padding: 80px 80px 90px;
  text-align: center;
}
#form-content-forgot-password .confirm-message .login-btn,
#form-content-login .confirm-message .login-btn {
  text-align: center;
}
#form-content-forgot-password .confirm-message .login-btn a,
#form-content-login .confirm-message .login-btn a {
  background: var(--color-cta-primary);
  border: none;
  border-radius: 20px;
  color: var(--color-bg-primary);
  text-decoration: none;
  cursor: pointer;
  font-size: 1.063rem;
  font-weight: 700;
  height: 40px;
  line-height: 100%;
  margin: 30px 0;
  padding: 10px 30px;
  text-align: center;
  transition: all 0.3s;
}
#form-content-forgot-password.password-email-done .confirm-message, #form-content-forgot-password.reset-done .confirm-message,
#form-content-login.password-email-done .confirm-message,
#form-content-login.reset-done .confirm-message {
  display: block;
}
#form-content-forgot-password.password-email-done .login-form-content, #form-content-forgot-password.reset-done .login-form-content,
#form-content-login.password-email-done .login-form-content,
#form-content-login.reset-done .login-form-content {
  display: none;
}

/*
 * Vendor
 *
 */
.brite-popup {
  position: fixed;
  z-index: 20000;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0 0 0 0;
  background: rgba(0, 0, 0, 0.5333333333);
  overflow: hidden;
}
.brite-popup div {
  height: 600px;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.3333333333);
  border-radius: 10px;
}
.brite-popup div iframe {
  border: 0;
  border-radius: 10px;
}

.brite-button {
  background-color: var(--color-cta-tertiary);
  border-radius: 6px;
  color: #fff;
  font-weight: 700;
  height: 30px;
  line-height: 29px;
  padding: 0 10px;
  text-decoration: none;
  border: 0;
}
.brite-button.active {
  pointer-events: none;
  background-color: var(--color-cta-tertiary-inactive);
}